import React, { Component } from "react";
import { connect } from "react-redux";
import AdminleadDetails from "../component/Adminleaddetails";
import {
  close_snack_bar
} from "../../../common/snackbar/action";
import {
  viewAllLeadWithPgn,
  createLeadDeal,
  editLeadDeal,
  viewAllDealByLeadId,
  createLeadNotes,
  viewAllNotesByLeadId,
  searchNotes,
  addFollowup,
  viewAllFollowup,
  updateLeadStatus,
  updateLeadType,
  viewAllLeadById,
  createPayment,
  searchPayment,
  viewAllPaymentByLeadId,
  view_lead_details,
  viewAllNotesByDealId,
  viewAllPaymentByDealId,
  viewAllFollowupDeal,
  updateDealStatusPriority,
  uploadQuote,
  viewAllQuoteByLeadId,
  updateLead,
  approveQuotation

} from "../action";
import { viewAllProductWithPgn } from "../../product/Action"
import { viewAllCategory } from "../../employee/actions"

export class Controller extends Component {
  render() {
    return (
      <AdminleadDetails {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    lead: store.leads,
    product: store.product,
    login: store.login,
    employee: store.employee,
    loader: store.loader,
    // snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAllLeadWithPgn: (org_id, page_number, rowper_page) => {
      dispatch(viewAllLeadWithPgn(org_id, page_number, rowper_page));
    },
    viewAllCategory: (org_id) => {
      dispatch(viewAllCategory(org_id));
    },
    createLeadDeal: (lead_id, product_name, admin_id, product_price, deal_status, priority, category) => {
      dispatch(createLeadDeal(lead_id, product_name, admin_id, product_price, deal_status, priority, category));
    },
    editLeadDeal: (lead_id, product_name, product_price, deal_id) => {
      dispatch(editLeadDeal(lead_id, product_name, product_price, deal_id));
    },
    viewAllProductWithPgn: (org_id, page_number, rowper_page) => {
      dispatch(viewAllProductWithPgn(org_id, page_number, rowper_page))
    },
    viewAllDealByLeadId: (lead_id) => {
      dispatch(viewAllDealByLeadId(lead_id))
    },

    createLeadNotes: (lead_id, admin_id, titel, note_description, page_number, rowper_page, deal_id) => {
      dispatch(createLeadNotes(lead_id, admin_id, titel, note_description, page_number, rowper_page, deal_id))
    },

    viewAllNotesByLeadId: (lead_id, page_number, rowper_page) => {
      dispatch(viewAllNotesByLeadId(lead_id, page_number, rowper_page))
    },

    searchNotes: (lead_id, page_number, rowper_page, name) => {
      dispatch(searchNotes(lead_id, page_number, rowper_page, name))
    },

    addFollowup: (lead_id, description, reminder, call_purpose, next_followup_date, deal_id, executive_id) => {
      dispatch(addFollowup(lead_id, description, reminder, call_purpose, next_followup_date, deal_id, executive_id))
    },
    updateDealStatusPriority: (deal_id, status, priority, lead_id) => {
      dispatch(updateDealStatusPriority(deal_id, status, priority, lead_id))
    },
    viewAllFollowup: (lead_id) => {
      dispatch(viewAllFollowup(lead_id))
    },
    view_lead_details: (lead_id) => {
      dispatch(view_lead_details(lead_id))
    },
    viewAllNotesByDealId: (deal_id, page_number, rowper_page, name) => {
      dispatch(viewAllNotesByDealId(deal_id, page_number, rowper_page, name))
    },
    viewAllPaymentByDealId: (deal_id, page_number, rowper_page, name) => {
      dispatch(viewAllPaymentByDealId(deal_id, page_number, rowper_page, name))
    },
    viewAllFollowupDeal: (deal_id) => {
      dispatch(viewAllFollowupDeal(deal_id))
    },
    viewAllLeadById: (lead_id) => {
      dispatch(viewAllLeadById(lead_id))
    },

    updateLeadStatus: (lead_id, status, org_id) => {
      dispatch(updateLeadStatus(lead_id, status, org_id))
    },

    updateLeadType: (lead_id, ptype) => {
      dispatch(updateLeadType(lead_id, ptype))
    },
    createPayment: (org_id, lead_id, paid_by, amount, page_number, rowper_page, deal_id) => {
      dispatch(createPayment(org_id, lead_id, paid_by, amount, page_number, rowper_page, deal_id))
    },
    searchPayment: (lead_id, page_number, rowper_page, name) => {
      dispatch(searchPayment(lead_id, page_number, rowper_page, name))
    },
    viewAllPaymentByLeadId: (lead_id, page_number, rowper_page) => {
      dispatch(viewAllPaymentByLeadId(lead_id, page_number, rowper_page))
    },

    uploadQuote: (org_id, lead_id, deal_id, amount, quotefile) => {
      dispatch(uploadQuote(org_id, lead_id, deal_id, amount, quotefile))
    },
    viewAllQuoteByLeadId: (deal_id, name) => {
      dispatch(viewAllQuoteByLeadId(deal_id, name))
    },
    approveQuotation: (deal_id, quote_id, amount) => {
      dispatch(approveQuotation(deal_id, quote_id, amount))
    },
    updateLead: (lead_id, name, email, phone, city, state, country, pin, address) => {
      dispatch(updateLead(lead_id, name, email, phone, city, state, country, pin, address))
    },


  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);