import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../components/login";


import {close_snack_bar} from "../../common/snackbar/action";
import {

  clear_login,
  phone_login,
  facebookLogin,
  googleLogin,
  social_login,
  verify_otp,
  email_login,
  verify_Email_Otp,
  login_auth,
  fetchToken

} from "../../auth/actions";
export class Controller extends Component {
  render() {
    return (
      <Login {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    clear_login: () => {
      dispatch(clear_login())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    phone_login: (phone) => {
      dispatch(phone_login(phone));
    },
    facebookLogin: () => {
      dispatch(facebookLogin());
    },
    googleLogin: () => {
      dispatch(googleLogin());
    },

    social_login: (login_id, otp) => {
      dispatch(social_login(login_id, otp));
    },
    verify_otp: (phone, otp) => {
      dispatch(verify_otp(phone, otp));
    },
    email_login: (email) => {
      dispatch(email_login(email));
    },
    verify_Email_Otp: (email,otp) => {
      dispatch(verify_Email_Otp(email,otp));
    },
    fetchToken: (token, product_id) => {
      dispatch(fetchToken(token, product_id));
    },
 
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
