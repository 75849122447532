const UNIVERSAL = {
  BASEURL: "https://crm-backend-a7otoh6gpq-el.a.run.app/",
  // BASEURL: "http://localhost:8050/",
  // BASEURL: "https://2df4-45-114-49-221.ngrok-free.app/",
  // SSO_URL: "http://localhost:8001/",
  SSO_URL: "https://ssoapi.technoboot.co/",
  // SSO_URL: "https://52c4-2409-40e2-f-f95e-e87b-9279-389-5a42.ngrok-free.app/",
};

export default UNIVERSAL;
