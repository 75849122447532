import { Button, Dialog, Grid, Typography, Box, TextField, DialogTitle, DialogContent, DialogActions, TableContainer, DialogContentText } from '@mui/material'
import React, { Component } from 'react';
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextArea from 'antd/es/input/TextArea';
import "../../../pages/allotment/Components/allotment.css";
import Drawer from '@mui/material/Drawer';
import Meta from "../../../pages/image/meta.png";
import Email from "../../../pages/image/email.png";
import Call from "../../../pages/image/call.png";
import Whatsapp from "../../../pages/image/whatsapp.png";
import Message from "../../../pages/image/message.png";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Chip from "@mui/material/Chip";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import { Link } from 'react-router-dom';
import Pagination from "@mui/material/Pagination";
import meta from "../../../pages/image/meta.png";
import {
    CitySelect,
    CountrySelect,
    StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import Checkbox from '@mui/material/Checkbox';
import { Eye, Trash, Add, CloseSquare, CloseCircle } from 'iconsax-react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default class Adminallotment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableview: false,
            toggleDrawer1: false,
            allotmentm: '',
            value: new Date(),
            calandericon: false,
            admincreateallot: false,
            admindeleteallot: false,
            admineditallot: false,
            leadModal: false,
            selectedValue: [],
            age: '',
            deletedash: false,
            right: false,
            personName: [],
            allotment_id: "",
            allortment: "",
            allortment_desc: "",
            lead_id: [],
            selectedAllotment: null,
            lead_details: [],
            leadssss: [],
            manager_id: '',
            page: 0,
            setPage: 0,
            rowsPerPage: 10,
            pincode: "",
            address: "",
            leadChecked: [],
            leadFilter: [],
            addtext: false,
            assign_to: '',
            countryid: 0,
            country: '',
            stateid: 0,
            state: '',
            cityid: 0,
            city: ' ',
            product: ' ',
            category: ' ',
            status: 'New',
            executive_id: 'none',
            filter: 'N',
        };
        this.handleSendMessage = this.handleSendMessage.bind(this)
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleCallButtonClick = this.handleCallButtonClick.bind(this)
        this.toggleDrawer1 = (open) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }

            this.setState({ ...this.state, right: open });
        };
        this.list = () => (
            <Grid style={{ width: "100%%" }}>
                <DialogTitle display={'flex'} justifyContent={'space-between'}>
                    <Typography variant='h6'>Allotments Details</Typography>
                    <CloseSquare
                        size="24"
                        color="black"
                        variant="Outline"
                        cursor={'pointer'}
                        onClick={() => {
                            this.setState({
                                toggleDrawer1: false,
                            });
                        }} />
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ fontWeight: "500", fontSize: "15px" }}>{this.state.allortment_desc}</Typography>
                    <Typography style={{ color: "gray", fontSize: "10px" }}>Last Activity: 22 March at 10AM</Typography>
                    <Grid mt={1} style={{ display: "flex", gap: "10px" }}>
                        <Typography style={{ fontSize: "15px", fontWeight: "600" }}>{this.state.tofullname}</Typography>
                        <Typography>Employee ID: {this.state.employeeID}</Typography>
                    </Grid>
                    <Grid mt={2} style={{ display: "flex", gap: "10px" }}>
                        <img onClick={() => this.handleButtonClick()} style={{ height: 25, width: 25 }} alt='' src={Whatsapp} />
                        <img onClick={this.handleEmailClick} style={{ height: 25, width: 25 }} alt='' src={Email} />
                        <img onClick={() => this.handleCallButtonClick()} style={{ height: 25, width: 25 }} alt='' src={Call} />
                        <img onClick={() => this.handleSendMessage()} style={{ height: 25, width: 25 }} alt='' src={Message} />
                    </Grid>
                    <Grid mt={2} style={{ width: "100%", border: "0.5px solid gray", height: "20%", borderRadius: 5, display: "flex", padding: '2%' }}>
                        <Grid>
                            <Typography style={{ fontSize: 12, textAlign: 'center' }}>Priority</Typography>
                            <Grid mt={0.5} style={{ display: "flex", alignItems: "center" }}>
                                <FiberManualRecordIcon style={{ color: (this.state.priority === "High" ? "green" : (this.state.priority === "Low" ? "red" : (this.state.priority === "Medium" ? "yellow" : "black"))), fontSize: 12, }} />
                                <Typography style={{ color: (this.state.priority === "High" ? "green" : (this.state.priority === "Low" ? "red" : (this.state.priority === "Medium" ? "yellow" : "black"))), fontSize: 12 }}>
                                    {this.state.priority !== (undefined || null) ? this.state.priority : "---"}
                                </Typography>
                            </Grid>
                        </Grid>
                        <hr style={{ backgroundColor: "gray", height: 30, width: 2 }} />
                        <Grid>
                            <Typography style={{ fontSize: 12, textAlign: 'center' }}>Due Date</Typography>
                            <Typography mt={0.5} style={{ fontSize: 12 }}>{this.state.due_date}</Typography>
                        </Grid>
                        <hr style={{ backgroundColor: "gray", height: 30, width: 2 }} />
                        <Grid>
                            <Typography style={{ fontSize: 12, textAlign: 'center' }}>Status</Typography>
                            <Grid mt={0.5} style={{ display: "flex", alignItems: "center" }}>
                                {(this.state.status === "Not Started" || this.state.status === "In Progress") ?
                                    <CircularProgress variant="determinate"
                                        value={(this.state.status === "Not Started" ? 25 : (this.state.status === "In Progress" && 75))}
                                        style={{
                                            color: "green",
                                            width: "18px",
                                            height: "18px",
                                            fontSize: "8px",

                                        }}
                                        thickness={6}
                                    />
                                    :
                                    <CheckCircleIcon
                                        variant="determinate"
                                        style={{
                                            color: "green",
                                            width: "20px",
                                            height: "20px",
                                            fontSize: "8px",
                                        }}
                                        thickness={6}
                                    />
                                }
                                <Typography ml={0.5} style={{ fontSize: 12 }}>{this.state.status !== (undefined || null) ? this.state.status : "---"}</Typography>
                            </Grid>
                        </Grid>
                        <hr style={{ backgroundColor: "gray", height: 30, width: 2 }} />
                        <Grid>
                            <Typography style={{ fontSize: 12, textAlign: 'center' }}>Task Assigned by</Typography>
                            <Typography mt={0.5} style={{ fontSize: 12 }}>{this.state.byfullname}</Typography>
                        </Grid>
                    </Grid>
                    <Typography mt={1} style={{ fontSize: 14 }}><b>Leads: </b> {this.state.leads !== (undefined || null) ? this.state.leads : "---"}</Typography>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            {Array.isArray(this.state.leadssss) && this.state.leadssss?.map((item, index) => {

                                return (
                                    <TableRow>
                                        <TableCell><Typography style={{ fontSize: 12 }}>{item.leadDetails.name ?? "---"}</Typography></TableCell>
                                        <TableCell><Typography style={{ fontSize: 12 }}>{item.leadDetails.email ?? "----"}</Typography></TableCell>
                                        <TableCell onClick={() => {
                                            this.props.setLeadDetails(item.leadDetails);
                                            this.props.view_lead_details(item.leadDetails._id)
                                        }}
                                            style={{ justifyContent: 'right' }}><Link to={`/AdminleadDetails/${item.leadDetails._id}`}><ArrowCircleRightIcon /></Link></TableCell>
                                    </TableRow>
                                );
                            })}
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Grid>
        )
    }

    handleChange = (event) => {
        this.setState({ allotmentm: event.target.value });
    };


    handleleads(event) {
        const { value } = event.target;
        this.setState({
            personName: typeof value === 'string' ? value.split(',') : value,
        });
    }

    handleDateChange = (e) => {
        const rawDate = e.target.value; // The raw date string from the input
        const formattedDate = this.formatDate(rawDate); // Format the date
        this.props.filterAllotmentByProgress(this.props.allotment.all_allotment_by_org_id, formattedDate, "date");
    }

    formatDate(rawDate) {
        // Assuming rawDate is in the format 'yyyy-MM-dd'
        const parts = rawDate.split('-');
        if (parts.length === 3) {
            const [year, month, day] = parts;
            return `${year}-${month}-${day}`;
        }
        return rawDate; // Return the original date if it's not in the expected format
    }

    handleEmailClick = () => {
        const emailAddress = this.props.allotment.assign_to_detail.email;
        // const emailAddress = '';

        // Construct the mailto link
        const mailtoLink = `mailto:${emailAddress}`;

        // Open the default email client
        window.location.href = mailtoLink;
    };

    handleButtonClick() {
        const phoneNumber = this.props.allotment.assign_to_detail.mobile;

        const whatsappUrl = `https://wa.me/${phoneNumber}`;

        window.open(whatsappUrl, '_blank');
    }

    handleSendMessage() {
        const messageURI = `sms:${this.props.allotment.assign_to_detail.mobile}`;

        window.location.href = messageURI;
    }

    handleCallButtonClick() {
        const telUrl = `tel:${this.props.allotment.assign_to_detail.mobile}`;

        window.location.href = telUrl;
    }

    componentDidMount() {
        this.props.viewAllExecutive();
        this.props.viewAllLead(this.props.login.org_id);
        this.props.viewAllotmentByOrg_id(this.props.login.org_id);
        this.props.viewAllManager();
        this.props.viewProductUser(this.props.login.org_id, 'crm')
        this.props.viewProductByOrgId(this.props.login.org_id)
        this.props.viewAllCategory(this.props.login.org_id);
        this.props.viewAllExecutiveByAdminNoPage(this.props.login.user_id, this.props.login.org_id);
    }

    render() {
        const handleChangePage = (event, newPage) => {
            this.props.viewAllProductWithPgn( this.props.login.org_id, newPage - 1, this.state.rowsPerPage);
            this.setState({ page: newPage });
        };
        const { countryid, stateid, } = this.state;

        return (
            <Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <FormControl style={{ width: "20%" }}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                              value={this.state.filter || 'N'}
                            onChange={(e) => {
                                this.setState({ filter: e.target.value });
                                this.props.filterAllotmentByProgress(this.props.allotment.all_allotment_by_org_id, e.target.value, "progress")
                            }}>
                            <MenuItem value='N'>All Allotment</MenuItem>
                            <MenuItem value="Complete">Complete</MenuItem>
                            <MenuItem value="In Progress">In Progress</MenuItem>
                            <MenuItem value="Not Started">Not Started</MenuItem>
                        </Select>
                    </FormControl>
                    <Grid style={{ display: "flex", gap: 1, alignItems: 'center'}}>
                        <FormControl sx={{ m: 1, width: '25ch' }}>
                            <input
                                type="date"
                                name="begin"
                                min="1997-01-01"
                                max="2030-12-31"
                                className="date-input"
                                onChange={this.handleDateChange}
                            />
                        </FormControl>
                            <Add
                                size="24px"
                                color="#525050"
                                variant="Bold"
                                cursor={'pointer'}
                                onClick={() => {
                                    this.props.clearSelect();
                                    this.setState({
                                        admincreateallot: true,
                                        lead_id: [],
                                        allortment_desc: '',
                                        priority: '',
                                        due_date: '',
                                        assign_to: '',
                                        countryid: 0,
                                        stateid: 0,
                                        cityid: 0,
                                        pincode: "",
                                        address: "",
                                        country: '',
                                        state: '',
                                        city: ' ',
                                        product: ' ',
                                        category: ' ',
                                        status: 'New',
                                        executive_id: 'none',
                                    });
                                }}
                            />
                    </Grid>
                </Grid>

                {/* -------------drawer---------- */}
                <Box >
                    <Drawer
                        anchor="right"
                        open={this.state.toggleDrawer1}
                        onClose={() => this.toggleDrawer1(false)}
                    >
                        {this.list()}
                    </Drawer>
                </Box>

                <Grid mt={1}>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead style={{ background: "lightgray" }}>
                                <TableRow>
                                    <TableCell style={{ color: "#3D3B3B", textAlign: "left" }}>Assign By</TableCell>
                                    <TableCell style={{ color: "#3D3B3B", textAlign: "left" }}>Assign To</TableCell>
                                    <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Leads Assigned</TableCell>
                                    <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Priority</TableCell>
                                    <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Status</TableCell>
                                    <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Due Date</TableCell>
                                    <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Task</TableCell>
                                    <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {Array.isArray(this.props.allotment.filter_allot) && this.props.allotment.filter_allot?.map((item) => {
                                    
                                    return (
                                        <TableRow>
                                            <TableCell style={{ textAlign: "left" }}>{item.assign_by_details?.name}</TableCell>
                                            <TableCell style={{ textAlign: "left" }}>{item.assign_to_detail?.name}</TableCell>
                                            {/* <TableCell style={{ textAlign: "center" }}>{item.leads !== (undefined || null) ? item.leads : "---"}</TableCell> */}
                                            <TableCell style={{ textAlign: "center", lineHeight: 2, textDecoration: 'none' }}>
                                                {item?.lead_detail?.length} Leads
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center" }}>
                                                <Typography style={{ color: (item.priority === "High" ? "green" : (item.priority === "Low" ? "red" : (item.priority === "Medium" ? "yellow" : "black"))) }}>
                                                    <FiberManualRecordIcon style={{ color: (item.priority === "High" ? "green" : (item.priority === "Low" ? "red" : (item.priority === "Medium" ? "yellow" : "black"))), fontSize: 12, }} /> {item?.priority ? item.priority : "---"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                                                    {(item.status === "Not Started" || item.status === "In Progress") ?
                                                        <CircularProgress variant="determinate"
                                                            value={(item.status === "Not Started" ? 25 : (item.status === "In Progress" && 75))}
                                                            style={{
                                                                color: "green",
                                                                width: "18px",
                                                                height: "18px",
                                                                fontSize: "8px",

                                                            }}
                                                            thickness={6}
                                                        />
                                                        :
                                                        <CheckCircleIcon
                                                            variant="determinate"
                                                            style={{
                                                                color: "green",
                                                                width: "20px",
                                                                height: "20px",
                                                                fontSize: "8px",
                                                            }}
                                                            thickness={6}
                                                        />
                                                    }
                                                    <Typography color={'black'}> {item?.status ? item.status : "---"}</Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center" }}>{item.due_date !== (undefined || null) ? item.due_date : "---"}</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>{item.allortment_desc !== (undefined || null) ? item.allortment_desc : "---"}</TableCell>
                                            <TableCell>
                                                <Grid style={{ display: "flex", justifyContent: 'space-evenly' }}>
                                                    <Eye
                                                        size="24px"
                                                        color="#A76D42"
                                                        variant="Outline"
                                                        cursor={'pointer'}
                                                        onClick={() => {
                                                            this.setState({
                                                                toggleDrawer1: true,
                                                                allortment_id: item._id,
                                                                allortment: item.allortment,
                                                                allortment_desc: item.allortment_desc,
                                                                create_date: item.create_date,
                                                                create_time: item.create_time,
                                                                due_date: item.due_date,
                                                                leadssss: item.lead_detail,
                                                                leads: item.lead_detail.length,
                                                                email: item.assign_to_detail.email_id,
                                                                employeeID: item.assign_to_detail.employeeID,
                                                                tofullname: item.assign_to_detail.fullname,
                                                                byfullname: item.assign_by_details.name,
                                                                mobile: item.assign_to_detail.mobile,
                                                                profile_pic: item.assign_to_detail.profile_pic,
                                                                role: item.assign_to_detail.role,
                                                                priority: item.priority,
                                                                status: item.status
                                                            });
                                                        }} />
                                                    <Trash
                                                        size="24px"
                                                        color="#C43232"
                                                        variant="Outline"
                                                        cursor={'pointer'}
                                                        onClick={() => {
                                                            this.setState({
                                                                admindeleteallot: true,
                                                                allotment_id: item._id
                                                            });
                                                        }} />
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <Pagination style={{ display: "flex", justifyContent: "flex-end", padding: '1%' }}
                            count={parseInt(this.props.allotment.allot_length / 10) + 1}
                            size="small"
                            className='pagination'
                            onChange={handleChangePage}
                        />
                    </TableContainer>
                </Grid>

                {/* ---------add----------- */}
                <Dialog fullWidth maxWidth='sm' open={this.state.admincreateallot}>
                    <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'#D9D9D9'} marginBottom={'20px'}>
                        <Typography variant='h6'>Create Allotment</Typography>
                        <CloseCircle
                            size="24"
                            color="black"
                            variant="Outline"
                            cursor={'pointer'}
                            onClick={() => {
                                this.setState({
                                    admincreateallot: false,
                                    lead_id: [],
                                    allortment_desc: '',
                                    priority: '',
                                    due_date: '',
                                    assign_to: '',
                                    countryid: 0,
                                    stateid: 0,
                                    cityid: 0,
                                    pincode: "",
                                    address: "",
                                    country: "",
                                    state: "",
                                    city: " ",
                                });
                            }} />
                    </DialogTitle>
                    <DialogContent style={{paddingTop: '10px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={2.2}>
                                <Typography>Assign To <span style={{color: 'red'}}>*</span>:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3.8}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Executive</InputLabel>
                                    <Select
                                        label="Select Executives"
                                        onChange={(e) => {
                                            this.setState({ assign_to: e.target.value });
                                        }}
                                    >
                                        {Array.isArray(this.props.employee.admin_user) && this.props.employee.admin_user.filter(item => item.type === "E").map((item) => {
                                            return (
                                                <MenuItem key={item._id} value={item._id}>
                                                    <Typography>{item.name}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={2.2}>
                                <Typography>Due Date <span style={{color: 'red'}}>*</span>:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3.8}>
                                <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                                    <input
                                        type="date"
                                        name="begin"
                                        min="1997-01-01"
                                        max="2030-12-31"
                                        className="date-input"
                                        onChange={(e) => {
                                            this.setState({ due_date: e.target.value });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={6} md={2.2}>
                                <Typography>Priority <span style={{color: 'red'}}>*</span>:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3.8}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Priority</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"

                                        label="Select Priority"
                                        onChange={(e) => {
                                            this.setState({ priority: e.target.value });
                                        }}

                                    >
                                        <MenuItem value="High">
                                            <Grid style={{ display: "flex", alignItems: "center" }}>
                                                <FiberManualRecordIcon style={{ color: "green", fontSize: 12, }} />
                                                <Typography style={{ color: "green" }}>High </Typography>
                                            </Grid>
                                        </MenuItem>
                                        <MenuItem value="Low">
                                            <Grid style={{ display: "flex", alignItems: "center" }}>
                                                <FiberManualRecordIcon style={{ color: "red", fontSize: 12, }} />
                                                <Typography style={{ color: "red" }}>Low </Typography>
                                            </Grid>
                                        </MenuItem>
                                        <MenuItem value="Moderate">
                                            <Grid style={{ display: "flex", alignItems: "center" }}>
                                                <FiberManualRecordIcon style={{ color: "yellow", fontSize: 12, }} />
                                                <Typography style={{ color: "yellow" }}>Moderate </Typography>
                                            </Grid>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={6} md={2.2}>
                                <Typography>Leads <span style={{color: 'red'}}>*</span>:</Typography>
                            </Grid>
                            <Grid item xs={8} display={'flex'} >
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Button fullWidth
                                            onClick={() => { this.setState({ leadModal: true }); }}
                                            variant='outlined' style={{ borderColor: "gray", color: "gray", height: '2.4375rem' }}>
                                            Select Leads
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography m={1}>{Array.isArray(this.props.allotment.view_all_leads_with_search) && this.props.allotment.view_all_leads_with_search.filter(item => item.checked === true).length} Leads Selected</Typography>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={1}>
                            {Array.isArray(this.props.allotment.view_all_leads_with_search) && this.props.allotment.view_all_leads_with_search.filter(item => item.checked === true).map((e) => {
                                
                                return (
                                    <Grid item key={e.id} xs={12} sm={3}>
                                        <Chip style={{ backgroundColor: 'lightgray', color: 'black' }} label={e.leadDetails?.name} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12}>
                                <Typography>Allotment Description :</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextArea
                                    placeholder='Enter Allotment Description'
                                    style={{ width: "100%", backgroundColor: "lightgray", borderRadius: 5 }}
                                    onChange={(e) => {
                                        this.setState({ allortment_desc: e.target.value });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ marginBottom: '10px', justifyContent: 'center' }}>
                        <Button
                            disabled={this.state.lead_id.length === 0 || this.state.assign_to === "" || this.state.due_date === "" || this.state.priority === "" }
                            onClick={() => {
                                this.setState({
                                    admincreateallot: false,
                                    lead_id: [],
                                    allortment_desc: '',
                                    priority: '',
                                    due_date: '',
                                    assign_to: '',
                                    countryid: 0,
                                    stateid: 0,
                                    cityid: 0,
                                    pincode: "",
                                    address: "",
                                    country: '',
                                    state: '',
                                    city: ' ',
                                    product: ' ',
                                    category: ' ',
                                    status: 'New',
                                    executive_id: 'none',
                                });
                                // 
                                this.props.addAllortment(this.props.login.org_id, this.props.login.user_id, this.state.assign_to, this.state.lead_id, this.state.due_date, this.state.priority, this.state.allortment_desc, "Not Started", this.state.page, this.state.rowsPerPage)
                            }}
                            variant='contained' style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }}>
                            Add Task
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* ----------edit--------------- */}
                <Dialog
                    open={this.state.admineditallot}>
                    <Grid style={{ height: "auto", width: "600px" }}>
                        <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10, backgroundColor: "lightgray" }}>
                            <Typography style={{ fontSize: "20px", fontWeight: "600" }}>Create Allotment</Typography>
                            <CloseOutlinedIcon
                                onClick={() => { this.setState({ admineditallot: false }); }}
                                style={{ fontSize: 20, color: "#000", cursor: "pointer" }} />
                        </Grid>

                        <Grid style={{ padding: 20 }}>

                            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                    <Typography style={{ whiteSpace: "nowrap" }}>Assign To:</Typography>
                                    <FormControl style={{ width: '20ch', marginLeft: "2%" }}>
                                        <InputLabel id="demo-simple-select-label">Select Executives</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Select Executives"
                                            value={this.state.manager_id}
                                            onChange={(e) => {
                                                this.setState({ manager_id: e.target.value });
                                            }}

                                        >
                                            {Array.isArray(this.props.employee.all_executive) && this.props.employee.all_executive.map((item) => {
                                                return (
                                                    <MenuItem value={item._id}>
                                                        {/* <Grid style={{ display: "flex", alignItems: "center" }}> */}
                                                        {/* <img src={item.profile_pic !== (null || undefined) && item.profile_pic} alt='' /> */}
                                                        <Typography>{item.fullname}</Typography>
                                                        {/* </Grid> */}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                    <Typography>Due Date:</Typography>
                                    <FormControl sx={{ m: 1, width: '20ch' }} variant="outlined">
                                        <input
                                            type="date"
                                            name="begin"
                                            min="1997-01-01"
                                            max="2030-12-31"
                                            className="date-input"
                                            value={this.state.due_date}
                                            onChange={(e) => {
                                                this.setState({ due_date: e.target.value });
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "2%" }}>
                                <Typography>Leads :</Typography>
                                <Grid
                                    style={{ width: "70%", marginLeft: "5%", display: "flex", flexDirection: "row", }}

                                >
                                    <Select
                                        style={{ width: "300px", marginLeft: "24%" }}
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={this.state.personName}
                                        onChange={(e) => {
                                            this.handleleads(e)
                                        }}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                    </Select>
                                    <Typography style={{ marginLeft: "30px" }}>40 Leads selected</Typography>
                                </Grid>

                            </Grid>

                            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "3%" }}>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                    <Typography style={{ whiteSpace: "nowrap" }}>Allotment:</Typography>
                                    <FormControl style={{ width: '20ch', marginLeft: "2%" }}>
                                        <TextField variant='outlined' placeholder='Enter medium to allot'
                                            style={{ width: "70%", borderRadius: "100px" }}
                                            value={this.state.allortment}
                                            onChange={(e) => {
                                                this.setState({ allortment: e.target.value });
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid style={{ display: "flex", alignItems: "center", marginRight: "2%" }}>
                                    <Typography>Priority:</Typography>
                                    <FormControl style={{ width: '20ch', marginLeft: "2%" }}>
                                        {/* <InputLabel id="demo-simple-select-label">Select Executives</InputLabel> */}
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"

                                            label="Select Executives"
                                            value={this.state.priority}
                                            onChange={(e) => {
                                                this.setState({ priority: e.target.value });
                                            }}

                                        >
                                            <MenuItem value="High">
                                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                                    <FiberManualRecordIcon style={{ color: "green", fontSize: 12, }} />
                                                    <Typography style={{ color: "green" }}>High </Typography>
                                                </Grid>
                                            </MenuItem>
                                            <MenuItem value="Low">
                                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                                    <FiberManualRecordIcon style={{ color: "red", fontSize: 12, }} />
                                                    <Typography style={{ color: "red" }}>Low </Typography>
                                                </Grid>
                                            </MenuItem>
                                            <MenuItem value="Moderate">
                                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                                    <FiberManualRecordIcon style={{ color: "yellow", fontSize: 12, }} />
                                                    <Typography style={{ color: "yellow" }}>Moderate </Typography>
                                                </Grid>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid style={{ marginTop: "3%" }}>
                                <Typography>Allotment Description:</Typography>
                                <TextArea
                                    placeholder='Lorem ipsum dolor sit amet. Cum perferendis voluptas ex minima architecto qui '
                                    style={{ width: "100%", backgroundColor: "lightgray", borderRadius: 5 }}
                                    value={this.state.allortment_desc}
                                    onChange={(e) => {
                                        this.setState({ allortment_desc: e.target.value });
                                    }}
                                >
                                </TextArea>
                            </Grid>

                            <Grid style={{ display: "flex", flexDirection: "row-reverse", alignSelf: "flex-end", marginTop: "4%" }}>
                                <Button
                                    onClick={() => { this.setState({ admineditallot: false }); }}
                                    variant='outlined' style={{ borderColor: "gray", color: "gray" }}>Cancel</Button>
                                <Button
                                    onClick={() => {
                                        this.setState({ admineditallot: false });
                                        this.props.updateAllortment(this.state.allortment_id, this.props.login.org_id, this.state.manager_id, this.state.leads, this.state.due_date, this.state.allortment, this.state.priority, this.state.allortment_desc, this.state.status, this.state.page, this.state.rowsPerPage)

                                    }}
                                    variant='contained' style={{ backgroundColor: "gray", color: "#fff", marginRight: "4%" }}>Edit Task</Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Dialog>

                {/* ---------------delete------------ */}
                <Dialog
                    maxWidth="xs"
                    open={this.state.admindeleteallot}
                >
                    <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'lightgray'}>
                        <Typography variant='h6'>Delete</Typography>
                        <CloseCircle
                            size="24"
                            color="black"
                            variant="Outline"
                            cursor={'pointer'}
                            onClick={() => {
                                this.setState({
                                    admindeleteallot: false,
                                });
                            }} />
                    </DialogTitle>
                    <DialogContent style={{ marginTop: '20px' }}>
                        <DialogContentText >Are you sure, you want to delete ?</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ marginBottom: '10px', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: "#3D3B3B", color: "#E5F6FF", borderRadius: 10, }}
                            onClick={() => {
                                this.setState({ admindeleteallot: false });
                                this.props.deleteAllotment(this.props.login.org_id, this.state.allotment_id, this.state.page, this.state.rowsPerPage)
                            }}
                            
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* ---------leads----------- */}
                <Dialog open={this.state.leadModal} fullWidth
                    maxWidth='lg'
                >
                    <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'lightgray'}>
                        <Typography variant='h6'>Select Lead</Typography>
                        <CloseCircle
                            size="24"
                            color="black"
                            variant="Outline"
                            cursor={'pointer'}
                            onClick={() => {
                                this.setState({
                                    leadModal: false,
                                    countryid: 0,
                                    stateid: 0,
                                    cityid: 0,
                                    pincode: "",
                                    address: "",
                                    country: '',
                                    state: '',
                                    city: ' ',
                                    product: '',
                                    category: '',
                                    status: 'New',
                                    executive_id: 'none',
                                    checked: false,
                                });
                            }} />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container mt={0.5} spacing={2} >
                        <Grid item xs={3} >
                                <FormControl variant='standard' fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.category || " "}
                                        onChange={(e) => {
                                            this.setState({ category: e.target.value })
                                        }}
                                    >
                                        <MenuItem value={' '}>
                                            All
                                        </MenuItem>
                                        {Array.isArray(this.props.setting.category) && this.props.setting.category.map((item) => (
                                            <MenuItem value={item._id}>
                                                <Typography>{item.cate_name}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant='standard' fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Product</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.product || ' '}
                                        onChange={(e) => {
                                            this.setState({ product: e.target.value })
                                        }}
                                    >
                                        <MenuItem value={' '}>
                                            All
                                        </MenuItem>
                                        {Array.isArray(this.props.product.view_product_by_org_id) && this.props.product.view_product_by_org_id.map((item) => (
                                            <MenuItem value={item.product_name}>
                                                <Typography>{item.product_name}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            <Grid item xs={3} >
                                <FormControl variant='standard' fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.status || 'New'}
                                        placeholder='Product'
                                        label="Product"
                                        onChange={(e) => {
                                            this.setState({ status: e.target.value })
                                        }}
                                    >
                                        <MenuItem value={'New'}>
                                            New
                                        </MenuItem>
                                        <MenuItem value={'Converted'}>
                                            Converted
                                        </MenuItem>
                                        <MenuItem value={'In Follow Up'}>
                                            In Follow Up
                                        </MenuItem>
                                        <MenuItem value={'Rejected'}>
                                            Rejected
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} >
                                <FormControl variant='standard' fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Executive</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.executive_id || "none"}
                                        placeholder='Product'
                                        label="Product"
                                        onChange={(e) => {
                                            this.setState({ executive_id: e.target.value })
                                        }}
                                    >
                                        <MenuItem value={'none'}>None</MenuItem>
                                        <MenuItem value={' '}>All</MenuItem>
                                        {Array.isArray(this.props.employee.admin_user) && this.props.employee.admin_user.filter(item => item.type === "E").map((item) => (
                                            <MenuItem value={item._id}>
                                                <Typography>{item.name}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={0.5}>
                            <Grid item xs={3}>
                                <CountrySelect
                                    onChange={(e) => {
                                        this.setState({ countryid: e.id, country: e.name });
                                    }}
                                    placeHolder="Select Country"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <StateSelect
                                    countryid={countryid}
                                    onChange={(e) => {
                                        this.setState({ stateid: e.id, state: e.name });
                                    }}
                                    placeHolder="Select State"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <CitySelect
                                    countryid={countryid}
                                    stateid={stateid}
                                    onChange={(e) => {
                                        this.setState({ cityid: e.id, city: e.name });
                                    }}
                                    placeHolder="Select City"
                                />
                            </Grid>
                            <Grid item xs={1.5} >
                                <Button fullWidth
                                    onClick={() => {
                                        this.setState({
                                            countryid: 0,
                                            stateid: 0,
                                            cityid: 0,
                                            pincode: "",
                                            address: "",
                                            country: '',
                                            state: '',
                                            city: ' ',
                                            product: ' ',
                                            category: ' ',
                                            status: 'New',
                                            executive_id: 'none',
                                        })
                                    }}
                                    variant='contained' style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', padding: '0.33675rem 0.6735rem', color: 'white' }}>Reset</Button>
                            </Grid>
                            <Grid item xs={1.5} >
                                <Button fullWidth
                                    // disabled={this.state.product_id === "" || this.state.category_id === "" || this.state.status_id === "" || this.state.executive_id === "" || this.state.countryname === "" || this.state.statename === "" || this.state.cityname === ""}
                                    onClick={() => {
                                        this.setState({ tableview: true })
                                        this.props.searchDeals(this.props.login.org_id, this.state.country, this.state.state, this.state.city, this.state.product, this.state.category, this.state.status, this.state.executive_id);
                                        
                                    }}
                                    variant='contained' style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', padding: '0.33675rem 0.6735rem', color: 'white' }}>Filter</Button>
                            </Grid>
                        </Grid>
                        <Grid m={2}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead style={{ background: "lightgray" }}>
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    checked={Array.isArray(this.props.allotment.view_all_leads_with_search) && this.props.allotment.view_all_leads_with_search.every(item => item.checked === true)}
                                                    onClick={() => {
                                                        this.props.allSelect(this.props.allotment.view_all_leads_with_search)
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell >Name</TableCell>
                                            <TableCell >Contact</TableCell>
                                            <TableCell >Source</TableCell>
                                            <TableCell >Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!this.state.tableview ?
                                            <TableRow>
                                                <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                                    <Typography> Select any one of the filter to view the leads according to the filter selected </Typography>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            Array.isArray(this.props.allotment.view_all_leads_with_search) && this.props.allotment.view_all_leads_with_search?.map((item, index) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell >
                                                            <Checkbox
                                                                value={item.leadDetails?.name}
                                                                checked={item.checked}
                                                                onClick={() => {
                                                                    this.props.checkedLead(this.props.allotment.view_all_leads_with_search, index)
                                                                }}
                                                            />

                                                        </TableCell>
                                                        <TableCell >{item.leadDetails.name}</TableCell>
                                                        <TableCell >{item.leadDetails.phone}</TableCell>
                                                        <TableCell ><Typography style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><img style={{ height: "15px", width: "18px" }} src={meta} alt="Meta Icon" />{item.source}</Typography></TableCell>
                                                        <TableCell >{item.leadDetails.status}</TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ marginBottom: '10px', justifyContent: 'center' }}>
                        <Button
                            onClick={() => { this.setState({ leadModal: false, checked: false, lead_id: this.props.allotment.view_all_leads_with_search.filter(item => item.checked === true).map(item => item._id) }); }}
                            variant='contained' style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }}>Done</Button>
                    </DialogActions>
                </Dialog>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
            </Grid>
        )
    }
}
