import {
  VIEW_ALL_EMPLOYEE,
  VIEW_ALL_EMPLOYEE_BY_ID,
  VIEW_ALL_CATEGORY,
  VIEW_ALL_MANAGER,
  VIEW_ALL_EXECUTIVE,
  SEARCH_MANAGER,
  SEARCH_EXECUTIVE,
  VIEW_ALL_EXECUTIVE_BY_ADMIN,
  VIEW_ALL_EXECUTIVE_BY_MANAGER,
  SEARCH_EXECUTIVE_BY_ADMIN,
  VIEW_ALL_MANAGER_BY_ORG_ID,
  SET_MANAGER_ID,
  SET_MANAGER_Name,
  SET_EXECUTIVE_DETAILS,
  VIEW_LEAD_BY_EXECUTIVE,
  VIEW_ALL_MANAGER_BY_ADMIN,
  ADMIN_USER
} from "./constant";

const initial_state = {
  all_employee: [],
  all_employee_by_id: [],
  all_category: [],
  all_manager: [],
  all_executive: [],
  search_manager: [],
  search_executive: [],
  all_executive_by_admin: [],
  all_executive_by_manager: [],
  search_executive_by_admin: [],
  all_manager_by_org_id: [],
  manager_id: "",
  fullname: "",
  man_length: 0,
  exe_length: 0,
  executive_details: "",
  all_lead_by_executive: [],
  lead_length: 0,
  all_manager_by_admin: [],
  manager_length: 0,
  admin_user:[],
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_EXECUTIVE_DETAILS:

      return {
        ...state,
        executive_details: action.payload
      };

    case VIEW_LEAD_BY_EXECUTIVE:

      return {
        ...state,
        all_lead_by_executive: action.payload, lead_length: action.count
      };

    case VIEW_ALL_EMPLOYEE:
      return {
        ...state,
        all_employee: action.payload
      };

    case VIEW_ALL_EMPLOYEE_BY_ID:
      return {
        ...state,
        all_employee_by_id: action.payload
      };

    case VIEW_ALL_CATEGORY:
      return {
        ...state,
        all_category: action.payload
      };

    case VIEW_ALL_MANAGER:
      return {
        ...state,
        all_manager: action.payload, search_manager: action.payload, man_length: action.count
      };

    case VIEW_ALL_MANAGER_BY_ADMIN:
      return {
        ...state,
        all_manager_by_admin: action.payload, manager_length: action.count
      };


    case VIEW_ALL_EXECUTIVE:
      return {
        ...state,
        all_executive: action.payload
      };

    case SEARCH_MANAGER:
      return {
        ...state,
        search_manager: action.payload
      };
    case SEARCH_EXECUTIVE:
      return {
        ...state,
        search_executive: action.payload
      };

    case VIEW_ALL_EXECUTIVE_BY_ADMIN:

      return {
        ...state,
        all_executive_by_admin: action.payload, search_executive_by_admin: action.payload, exe_length: action.count
      };

    case SEARCH_EXECUTIVE_BY_ADMIN:
      return {
        ...state,
        search_executive_by_admin: action.payload
      };

    case VIEW_ALL_EXECUTIVE_BY_MANAGER:

      return {
        ...state,
        all_executive_by_manager: action.payload
      };

    case VIEW_ALL_MANAGER_BY_ORG_ID:
      return {
        ...state,
        all_manager_by_org_id: action.payload
      };

    case SET_MANAGER_ID:
      return {
        ...state,
        manager_id: action.payload
      };

    case SET_MANAGER_Name:
      return {
        ...state,
        fullname: action.payload
      };
    case ADMIN_USER:
      return {
        ...state,
        admin_user: action.payload, search_manager: action.payload, search_executive: action.payload
      };


    default:
      return state;
  }
}
