export const VIEW_ALL_EMPLOYEE = "VIEW_ALL_EMPLOYEE";
export const VIEW_ALL_EMPLOYEE_BY_ID = "VIEW_ALL_EMPLOYEE_BY_ID";
export const VIEW_ALL_CATEGORY = "VIEW_ALL_CATEGORY";
export const VIEW_ALL_MANAGER = "VIEW_ALL_MANAGER";
export const VIEW_ALL_EXECUTIVE = "VIEW_ALL_EXECUTIVE";
export const VIEW_ALL_EXECUTIVE_BY_ADMIN = "VIEW_ALL_EXECUTIVE_BY_ADMIN";
export const VIEW_ALL_EXECUTIVE_BY_MANAGER = "VIEW_ALL_EXECUTIVE_BY_MANAGER";
export const SEARCH_MANAGER = "SEARCH_MANAGER";
export const SEARCH_EXECUTIVE = "SEARCH_EXECUTIVE";
export const SEARCH_EXECUTIVE_BY_ADMIN = "SEARCH_EXECUTIVE_BY_ADMIN";
export const VIEW_ALL_MANAGER_BY_ORG_ID = "VIEW_ALL_MANAGER_BY_ORG_ID";
export const SET_MANAGER_ID = "SET_MANAGER_ID";
export const SET_MANAGER_Name = "SET_MANAGER_Name";
export const SET_EXECUTIVE_DETAILS = "SET_EXECUTIVE_DETAILS";
export const VIEW_LEAD_BY_EXECUTIVE = "VIEW_LEAD_BY_EXECUTIVE";
export const VIEW_ALL_MANAGER_BY_ADMIN = "VIEW_ALL_MANAGER_BY_ADMIN";
export const ADMIN_USER = "ADMIN_USER";