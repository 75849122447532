/* eslint-disable no-lone-blocks */
import React, { Component } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
// import TabContext from '@mui/lab/TabContext';
// import Checkbox from '@mui/material/Checkbox';
import Empimg from "../../image/empimg.png";
import Infocircle from "../../image/info-circle.svg";
import CloseIcon from '@mui/icons-material/Close';

import {
  Grid,
  Dialog,
  TextField

} from "@mui/material";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Sale from "../../image/Sales.svg";
import Sold from "../../image/Sold.svg";
import Order from "../../image/Order.svg";
import Customer from "../../image/Customer.svg";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "../../dashboard/Components/newdashboard.css";
import Chart from 'react-apexcharts';
import TotalManager from "../../image/manager.png";
import TotalExecutive from "../../image/executive.png";
import { TickSquare, Profile2User, People, Add, Edit2, Trash, CloseCircle } from 'iconsax-react';
// import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';

const chartSetting = {
  yAxis: [
    {

    },
  ],
  width: 700,
  height: 300,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
  },
};

const dataset = [
  {
    convert: 39,
    infollow: 37,
    month: 'Jan',
  },
  {
    convert: 30,
    infollow: 32,
    month: 'Fev',
  },
  {
    convert: 47,
    infollow: 33,
    month: 'Mar',
  },
  {
    convert: 34,
    infollow: 36,
    month: 'Apr',
  },
  {
    convert: 37,
    infollow: 69,
    month: 'May',
  },
  {
    convert: 40,
    infollow: 43,
    month: 'June',
  },
  {
    convert: 39,
    infollow: 40,
    month: 'July',
  },
  {
    convert: 43,
    infollow: 40,
    month: 'Aug',
  },
  {
    convert: 31,
    infollow: 31,
    month: 'Sept',
  },
  {
    convert: 40,
    infollow: 43,
    month: 'Oct',
  },
  {
    convert: 47,
    infollow: 44,
    month: 'Nov',
  },
  {
    convert: 41,
    infollow: 20,
    newYork: 103,
    seoul: 23,
    month: 'Dec',
  },
];

const valueFormatter = (value) => `${value}`;
export default class newdashboard extends Component {
  constructor(props) {
    super(props);
    const today = new Date().toISOString().split('T')[0]; // Get today's date in "YYYY-MM-DD" format
    this.state = {
      startDate: '',
      endDate: '',
      admidashdetails: false,
      data: [
        { month: 'January', infollow: 50, convert: 20 },
        { month: 'February', infollow: 60, convert: 25 },
        { month: 'march', infollow: 60, convert: 25 },
        { month: 'april', infollow: 60, convert: 25 },
        { month: 'may', infollow: 60, convert: 25 },
        // ... add data for other months
      ],
      mngdata: [
        {
          empname: "Kristin Watson",
          empid: "EMP123456",
          empmail: "abc@gmail.com",
          emprole: "Sales Manager",
          empprog: "70 % Completed",
        },

        {
          empname: "Kristin Watson",
          empid: "EMP123456",
          empmail: "abc@gmail.com",
          emprole: "Sales Manager",
          empprog: "70 % Completed",
        },


      ],
      exedata: [
        {
          exeempname: "Kristin Watson",
          exeempid: "EMP123456",
          exeempmail: "abc@gmail.com",
          exeemprole: "Sales Representative",
          exeempprog: "70 % Completed",
        },
        {
          exeempname: "Kristin Watson",
          exeempid: "EMP123456",
          exeempmail: "abc@gmail.com",
          exeemprole: "Sales Representative",
          exeempprog: "70 % Completed",
        },
        {
          exeempname: "Kristin Watson",
          exeempid: "EMP123456",
          exeempmail: "abc@gmail.com",
          exeemprole: "Sales Representative",
          exeempprog: "70 % Completed",
        },
        {
          exeempname: "Kristin Watson",
          exeempid: "EMP123456",
          exeempmail: "abc@gmail.com",
          exeemprole: "Sales Representative",
          exeempprog: "70 % Completed",
        },
        {
          exeempname: "Kristin Watson",
          exeempid: "EMP123456",
          exeempmail: "abc@gmail.com",
          exeemprole: "Sales Representative",
          exeempprog: "70 % Completed",
        },
        {
          exeempname: "Kristin Watson",
          exeempid: "EMP123456",
          exeempmail: "abc@gmail.com",
          exeemprole: "Sales Representative",
          exeempprog: "70 % Completed",
        },
        {
          exeempname: "Kristin Watson",
          exeempid: "EMP123456",
          exeempmail: "abc@gmail.com",
          exeemprole: "Sales Representative",
          exeempprog: "70 % Completed",
        },
        {
          exeempname: "Kristin Watson",
          exeempid: "EMP123456",
          exeempmail: "abc@gmail.com",
          exeemprole: "Sales Representative",
          exeempprog: "70 % Completed",
        },
      ],
      series: [{
        name: 'New Customer',
        data: [31, 40, 28, 51, 42, 109, 100]

      }, {
        name: 'Repeated Customer',
        data: [60, 80, 70, 52, 64, 52, 41]
      }],
      options: {
        chart: {
          height: 350,
          width: 600,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        colors: ['#79A495', '#CC542E'],
        xaxis: {
          type: 'datetime',
          categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        },

      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]

    };
  }

  componentDidMount() {
    {
      this.props.login.type === "Owner" &&
        this.props.viewSalePrice(this.props.login.org_id, this.state.startDate, this.state.endDate);
      this.props.viewAllOrder(this.props.login.org_id, this.state.startDate, this.state.endDate);
      this.props.viewAllNewCustomer(this.props.login.org_id, this.state.startDate, this.state.endDate)
    }
    {
      this.props.login.type === "M" &&
        this.props.viewSalePrice(this.props.login.org_id, this.state.startDate, this.state.endDate);
      this.props.viewAllOrder(this.props.login.org_id, this.state.startDate, this.state.endDate);
      this.props.viewAllNewCustomer(this.props.login.org_id, this.state.startDate, this.state.endDate)
    }
    {
      this.props.login.type === "E" &&
        this.props.viewSalePriceExecutive(this.props.login.org_id, this.props.login.user_id, this.state.startDate, this.state.endDate);
      this.props.viewExecutiveOrder(this.props.login.user_id, this.state.startDate, this.state.endDate);
      this.props.viewExecutiveNewCustomer(this.props.login.user_id, this.state.startDate, this.state.endDate)
    }



    this.props.viewAllManager();
    this.props.viewAllExecutiveByAdmin(this.props.login.user_id, this.props.login.org_id);
    this.props.viewAllSalesByOrgId(this.props.login.org_id);
    this.props.managerOfTheMonth(this.props.login.org_id)
    this.props.executiveOfTheMonth(this.props.login.org_id)
    this.props.viewLeadDataByWeek(this.props.login.org_id)
  }

  handleStartDateChange = (event) => {
    this.setState({ startDate: event.target.value });
  };

  handleEndDateChange = (event) => {
    this.setState({ endDate: event.target.value });
  };

  render() {

    return (
      <div>
        <Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7} lg={6}>
              <Card style={{ padding: '10px' }}>
                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {this.props.login.type === "E" ?
                    <div>
                      <Typography variant='h6'>Total Leads</Typography>
                      <Typography variant='h7'>Lead’s Summary</Typography>
                    </div>
                    :
                    <div>
                      <Typography variant='h6'>Total Sales</Typography>
                      <Typography variant='h7'>Sale’s Summary</Typography>
                    </div>
                  }
                  <div style={{ display: 'flex', gap: '2px' }}>
                    <TextField
                      id="start-date"
                      label="Start Date"
                      size="small"
                      type="date"
                      value={this.state.startDate}
                      onChange={this.handleStartDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': { height: '0.7rem' },
                        width: "145px",
                      }}
                    />
                    <TextField
                      id="end-date"
                      label="End Date"
                      size='small'
                      type="date"
                      value={this.state.endDate}
                      onChange={this.handleEndDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': { height: '0.7rem' },
                        width: "145px",
                      }}
                    />
                    <TickSquare
                      size="32"
                      color="var(--PrimaryColour-G1, #3D3B3B)"
                      variant="Bold"
                      onClick={() => {
                        if (this.props.login.type === "E") {
                          this.props.viewExecutiveNewCustomer(this.props.login.user_id, this.state.startDate, this.state.endDate);
                          this.props.viewSalePriceExecutive(this.props.login.user_id, this.state.startDate, this.state.endDate);
                        }
                        this.props.viewSalePrice(this.props.login.org_id, this.state.startDate, this.state.endDate);
                        this.props.viewAllOrder(this.props.login.org_id, this.state.startDate, this.state.endDate);
                        this.props.viewAllNewCustomer(this.props.login.org_id, this.state.startDate, this.state.endDate)
                      }}
                    />
                  </div>
                </Grid>
                <Grid container spacing={2} paddingTop={2}>
                  <Grid item xs={6} >
                    <Box style={{ boxShadow: "0px 1px 7px 0px #00000040", borderRadius: "10px" }}>
                      <img src={Sale} alt='' style={{ height: "60px", borderTopLeftRadius: "2px" }} />
                      <Box style={{ padding: " 2px 13px" }}>
                        <Typography style={{ fontWeight: "500", color: "#000000" }}>₹ {this.props.login.type === "E" ? this.props.dashboard.executive_sale_price : this.props.dashboard.total_sale_price}</Typography>
                        <Typography style={{ fontSize: "16px", fontWeight: "400", color: "#8F8F8F" }}>Total Sales</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box style={{ boxShadow: "0px 1px 7px 0px #00000040", borderRadius: "10px" }}>
                      <img src={Order} alt='' style={{ height: "60px", borderTopLeftRadius: "2px" }} />
                      <Box style={{ padding: " 2px 13px" }}>
                        <Typography style={{ fontWeight: "500", color: "#000000" }}>{this.props.login.type === "E" ? this.props.dashboard.executive_order : this.props.dashboard.all_order}</Typography>
                        <Typography style={{ fontSize: "16px", fontWeight: "400", color: "#8F8F8F" }}>{this.props.login.type === "E" ? "Today's Leads" : 'Total Orders'}</Typography>
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
                <Grid container spacing={2} paddingTop={2}>
                  <Grid item xs={6} >
                    <Box style={{ boxShadow: "0px 1px 7px 0px #00000040", borderRadius: "10px" }}>
                      <img src={Sold} alt='' style={{ height: "60px", borderTopLeftRadius: "2px" }} />
                      <Box style={{ padding: " 2px 13px" }}>
                        <Typography style={{ fontWeight: "500", color: "#000000" }}>{this.props.login.type === "E" ? this.props.dashboard.executive_sale.length : this.props.dashboard.total_sale.length}</Typography>
                        <Typography style={{ fontSize: "16px", fontWeight: "400", color: "#8F8F8F" }}>Product Sold</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box style={{ boxShadow: "0px 1px 7px 0px #00000040", borderRadius: "10px" }}>
                      <img src={Customer} alt='' style={{ height: "60px", borderTopLeftRadius: "2px" }} />
                      <Box style={{ padding: " 2px 13px" }}>
                        <Typography style={{ fontWeight: "500", color: "#000000" }}>{this.props.login.type === "E" ? this.props.dashboard.executive_new_customer : this.props.dashboard.all_new_customer}</Typography>
                        <Typography style={{ fontSize: "16px", fontWeight: "400", color: "#8F8F8F" }}>New Customers</Typography>
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
              </Card>
            </Grid>
            {this.props.login.type === "Owner" &&
              <Grid item xs={12} sm={12} md={5} lg={6}>
                <Card style={{ padding: '10px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Card sx={{ borderRadius: "10px", boxShadow: "2px 2px 8px 0px #66666659" }}>
                        <CardActions>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={5}>
                              <div style={{
                                backgroundColor: '#EDD0FF',
                                borderRadius: '50%',
                                width: '70px',
                                height: '70px',
                                padding: '20px',
                              }}>
                                <Profile2User
                                  size="32"
                                  color="#9E37DD"
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              <Typography style={{ fontSize: "16px", color: "#000000", fontWeight: 400 }}>
                                Total Managers<br />
                                <b>{Array.isArray(this.props.employee.admin_user) ? this.props.employee.admin_user.filter(item => item.type === "M").length : 0}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card sx={{ borderRadius: "10px", boxShadow: "2px 2px 8px 0px #66666659" }}>
                        <CardActions>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={5}>
                              <div style={{
                                backgroundColor: '#C7FFA5',
                                borderRadius: '50%',
                                width: '70px',
                                height: '70px',
                                padding: '20px',
                              }}>
                                <People
                                  size="32"
                                  color="#33922B"
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              <Typography style={{ fontSize: "16px", color: "#000000", fontWeight: 400 }}>
                                Total Executives<br />
                                <b>{Array.isArray(this.props.employee.admin_user) ? this.props.employee.admin_user.filter(item => item.type === "E").length : 0}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            }
          </Grid>

          {/* first row card */}
          <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "18px", marginRight: "10%" }}>

            {/* best employee of the month */}

            {/* <Grid>
              <Grid className="tablee" style={{boxShadow: "0px 1px 7px 0px #00000040", padding: "10px", height: "350px", overflowY: "scroll"}}>
                <Typography style={{ fontSize: "18px", fontWeight: "600" }}>Best Employees of Month</Typography>

                <Grid>
                  <Tabs style={{ width: "450px" }}>
                    <TabList className="main-tab">
                      <Tab className="tabii"><span className='active'>Managers</span></Tab>
                      <Tab className="tabii">Executives</Tab>
                    </TabList>
                    <TabPanel>
                      <Table size="small" aria-label="a dense table">

                        <TableHead >
                          <TableRow style={{ height: "70px" }}>

                            <TableCell style={{ textAlign: "center", fontSize: "18px", color: "#525D79" }}></TableCell>
                            <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#525D79" }}> Employee Name</TableCell>
                            <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#525D79" }}>Employee ID</TableCell>
                            <TableCell style={{ textAlign: "center", fontSize: "18px", color: "#525D79" }}>Info</TableCell>


                          </TableRow>
                        </TableHead>

                        {Array.isArray(this.props.dashboard.manager_of_the_month) && this.props.dashboard.manager_of_the_month.map((item) => {
                          return (
                            <TableBody className="tablebody" style={{}} >


                              <TableRow >


                                <TableCell style={{ textAlign: "center", fontSize: "14px" }}>

                                </TableCell>

                                <TableCell style={{ textAlign: "center", fontSize: "14px" }}>
                                  <Typography style={{ fontSize: "15px" }}>{item.name}</Typography>
                                </TableCell>

                                <TableCell style={{ textAlign: "center", fontSize: "14px" }}>
                                  <Typography style={{ fontSize: "15px" }}>{item.employeeID}</Typography>
                                </TableCell>

                                <TableCell style={{ textAlign: "center", fontSize: "18px", cursor: "pointer" }}>
                                  <img alt=''
                                    onClick={() => {
                                      this.setState({ admidashdetails: true });
                                    }}

                                    src={Infocircle} />
                                </TableCell>




                              </TableRow>

                            </TableBody>
                          );
                        })}

                      </Table>
                    </TabPanel>
                    <TabPanel>
                      <Table size="small" aria-label="a dense table">

                        <TableHead >
                          <TableRow style={{ height: "70px" }}>

                            <TableCell style={{ textAlign: "center", fontSize: "18px", color: "#525D79" }}></TableCell>
                            <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#525D79" }}> Employee Name</TableCell>
                            <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#525D79" }}>Employee ID</TableCell>
                            <TableCell style={{ textAlign: "center", fontSize: "18px", color: "#525D79" }}>Info</TableCell>


                          </TableRow>
                        </TableHead>

                        {Array.isArray(this.props.dashboard.executive_of_the_month) && this.props.dashboard.executive_of_the_month.map((item) => {
                          return (
                            <TableBody className="tablebody" >


                              <TableRow >


                                <TableCell style={{ textAlign: "center", fontSize: "14px" }}>

                                </TableCell>

                                <TableCell style={{ textAlign: "center", fontSize: "14px" }}>
                                  <Typography style={{ fontSize: "15px" }}>{item.fullname}</Typography>
                                </TableCell>

                                <TableCell style={{ textAlign: "center", fontSize: "14px" }}>
                                  <Typography style={{ fontSize: "15px" }}>{item.employeeID}</Typography>
                                </TableCell>

                                <TableCell style={{ textAlign: "center", fontSize: "18px", cursor: "pointer" }}>
                                  <img alt='' src={Infocircle} />
                                </TableCell>




                              </TableRow>

                            </TableBody>
                          );
                        })}

                      </Table>
                    </TabPanel>
                  </Tabs>
                </Grid>
              </Grid>
            </Grid> */}


            {/* bar chart code here */}
            {/* <Grid style={{ boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.25)" }}>
              <Tabs >
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "15px" }}>
                  <Typography style={{ fontWeight: "500", fontSize: "18px", textAlign: "center" }}>Leads Analytics</Typography>

                  <TabList className='tab-list'>
                    <Tab><Button className='tab-list-btn'>Weekly</Button></Tab>
                    <Tab> <Button className='tab-list-btn'>Monthly</Button></Tab>

                  </TabList>
                </Box>

                <TabPanel>
                  <BarChart
                    dataset={dataset}
                    xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                    series={[
                      { dataKey: 'infollow', label: 'In-follow', valueFormatter, color: '#668E80' },
                      { dataKey: 'convert', label: 'Converted', valueFormatter, color: '#EE6C43' },
                    ]}
                    {...chartSetting}
                  />
                </TabPanel>


                <TabPanel>
                  <BarChart
                    dataset={dataset}
                    xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                    series={[
                      { dataKey: 'infollow', label: 'In-follow', valueFormatter, color: '#668E80' },
                      { dataKey: 'convert', label: 'Converted', valueFormatter, color: '#EE6C43' },

                    ]}
                    {...chartSetting}
                  />


                </TabPanel>
              </Tabs>
            </Grid> */}
          </Grid>

          {/* second row card */}
          <Grid style={{ display: "flex", justifyContent: "space-between", gap: "25px", marginTop: "5%", marginRight: "90px", marginLeft: "10px" }}>

            {/* <Grid style={{
              padding: "16px", boxShadow: " 4px 4px 8px 0px #00000040", width: "45%"
            }}>
              <Grid>
                <Box style={{ display: "flex", gap: "14px" }}>
                  <Box style={{ width: "200px", boxShadow: "0px 1px 7px 0px #00000040", borderRadius: "2px" }}>

                    <img src={TotalManager} alt='' style={{ height: "60px", borderTopLeftRadius: "2px" }} />
                    <Box style={{ padding: " 2px 13px" }}>
                      <Typography style={{ fontWeight: "500", color: "#000000" }}>{this.props.employee.man_length}</Typography>
                      <Typography style={{ fontSize: "16px", fontWeight: "400", color: "#8F8F8F" }}>Total Manager</Typography>
                    </Box>
                  </Box>

                  <Box style={{ width: "200px", boxShadow: "0px 1px 7px 0px #00000040", borderRadius: "2px" }}>

                    <img src={TotalExecutive} alt='' style={{ height: "60px", borderTopLeftRadius: "2px" }} />
                    <Box style={{ padding: " 2px 13px" }}>
                      <Typography style={{ fontWeight: "500", color: "#000000" }}>{this.props.dashboard.all_order}{this.props.employee.exe_length}</Typography>
                      <Typography style={{ fontSize: "16px", fontWeight: "400", color: "#8F8F8F" }}>Total Executive</Typography>

                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid> */}





            {/* <Grid>
              <Grid className="tablee" style={{ marginLeft: "15px", boxShadow: "0px 1px 7px 0px #00000040", padding: "10px", height: "320px", overflowY: "scroll" }}>
                <Typography style={{ fontSize: "18px", fontWeight: "600" }}>Best Employees of Month</Typography>

                <Grid>
                  <Tabs style={{ width: "450px" }}>
                    <TabList className="main-tab">
                      <Tab className="tabii"><span className='active'>Managers</span></Tab>
                      <Tab className="tabii">Executives</Tab>
                    </TabList>
                    <TabPanel>
                      <Table size="small" aria-label="a dense table">

                        <TableHead >
                          <TableRow style={{ height: "70px" }}>

                            <TableCell style={{ textAlign: "center", fontSize: "18px", color: "#525D79" }}></TableCell>
                            <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#525D79" }}> Employee Name</TableCell>
                            <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#525D79" }}>Employee ID</TableCell>
                            <TableCell style={{ textAlign: "center", fontSize: "18px", color: "#525D79" }}>Info</TableCell>


                          </TableRow>
                        </TableHead>

                        {Array.isArray(this.props.dashboard.manager_of_the_month) && this.props.dashboard.manager_of_the_month.map((item) => {
                          return (
                            <TableBody className="tablebody" style={{}} >


                              <TableRow >


                                <TableCell style={{ textAlign: "center", fontSize: "14px" }}>
                                  
                                </TableCell>

                                <TableCell style={{ textAlign: "center", fontSize: "14px" }}>
                                  <Typography style={{ fontSize: "15px" }}>{item.fullname}</Typography>
                                </TableCell>

                                <TableCell style={{ textAlign: "center", fontSize: "14px" }}>
                                  <Typography style={{ fontSize: "15px" }}>{item.employeeID}</Typography>
                                </TableCell>

                                <TableCell style={{ textAlign: "center", fontSize: "18px", cursor: "pointer" }}>
                                  <img alt=''
                                    onClick={() => {
                                      this.setState({ admidashdetails: true });
                                    }}

                                    src={Infocircle} />
                                </TableCell>




                              </TableRow>

                            </TableBody>
                          );
                        })}

                      </Table>
                    </TabPanel>
                    <TabPanel>
                      <Table size="small" aria-label="a dense table">

                        <TableHead >
                          <TableRow style={{ height: "70px" }}>

                            <TableCell style={{ textAlign: "center", fontSize: "18px", color: "#525D79" }}></TableCell>
                            <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#525D79" }}> Employee Name</TableCell>
                            <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#525D79" }}>Employee ID</TableCell>
                            <TableCell style={{ textAlign: "center", fontSize: "18px", color: "#525D79" }}>Info</TableCell>


                          </TableRow>
                        </TableHead>

                        {Array.isArray(this.props.dashboard.executive_of_the_month) && this.props.dashboard.executive_of_the_month.map((item) => {
                          return (
                            <TableBody className="tablebody" >


                              <TableRow >


                                <TableCell style={{ textAlign: "center", fontSize: "14px" }}>
                             
                                </TableCell>

                                <TableCell style={{ textAlign: "center", fontSize: "14px" }}>
                                  <Typography style={{ fontSize: "15px" }}>{item.fullname}</Typography>
                                </TableCell>

                                <TableCell style={{ textAlign: "center", fontSize: "14px" }}>
                                  <Typography style={{ fontSize: "15px" }}>{item.employeeID}</Typography>
                                </TableCell>

                                <TableCell style={{ textAlign: "center", fontSize: "18px", cursor: "pointer" }}>
                                  <img alt='' src={Infocircle} />
                                </TableCell>




                              </TableRow>

                            </TableBody>
                          );
                        })}

                      </Table>
                    </TabPanel>
                  </Tabs>
                </Grid>
              </Grid>
            </Grid> */}



            {/* wave chart code here */}
            {/* <Grid style={{ boxShadow: "0px 1px 7px 0px #00000040", padding: "10px" }}>
              <Typography style={{ fontWeight: "500", fontSize: "20px" }}>Customers</Typography>
              <div id="chart">
                <Chart options={this.state.options} series={this.state.series} type="area" height={300} />
              </div>
            </Grid> */}

          </Grid>

        </Grid >





        <Dialog
          open={this.state.admidashdetails} style={{}}>
          <Grid style={{ height: "auto", width: "450px", marginBottom: "30px", cursor: "pointer" }}>

            <Box >
              <CloseIcon
                onClick={() => { this.setState({ admidashdetails: false }); }}
                style={{ marginLeft: "400px", marginTop: "20px" }} />
              <center>

                <Typography className='Create'>Details Of Employee</Typography></center>


              <Grid style={{ marginTop: "5%" }}>


                <Typography className='Name' style={{}}> User Name </Typography>
                <TextField style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard" />



                <Typography className='Name' style={{ marginTop: "10px" }}>Email</Typography>
                <TextField type="Email" style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard" />

                <Typography className='Name' style={{ marginTop: "10px" }}>Phone Number</Typography>
                <TextField type="number" style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard" />

                <Typography className='Name' style={{ marginTop: "10px" }}>Employee Id</Typography>
                <TextField style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard" />

                <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 20px", marginTop: "8%" }}>
                  <div style={{ display: "flex", flexDirection: 'row', marginLeft: "2%" }}>


                    <Card className='cardddddd'>
                      <CardActions>


                        <Typography className='total'>
                          Total Lead<br />
                          <b style={{ color: "#000", marginLeft: "5%" }}>400</b>

                        </Typography>
                      </CardActions>
                    </Card>
                    <Card className='cardddd'>
                      <CardActions>


                        <Typography className='total'>
                          Converted Lead<br />
                          <b style={{ color: "#000", marginLeft: "5%" }}>600</b>

                        </Typography>
                      </CardActions>
                    </Card>





                  </div>

                </Grid>


                <center>
                  <button className='buttnn'
                    onClick={() => { this.setState({ admidashdetails: false }); }}
                    style={{ color: "black" }}>Okay</button>
                </center>

              </Grid>




            </Box>

          </Grid>
        </Dialog>

      </div >
    )
  }
}