import {
    VIEW_ALL_EMPLOYEE,
    VIEW_ALL_EMPLOYEE_BY_ID,
    VIEW_ALL_CATEGORY,
    VIEW_ALL_MANAGER,
    VIEW_ALL_EXECUTIVE,
    SEARCH_MANAGER,
    VIEW_ALL_EXECUTIVE_BY_ADMIN,
    VIEW_ALL_EXECUTIVE_BY_MANAGER,
    SEARCH_EXECUTIVE_BY_ADMIN,
    VIEW_ALL_MANAGER_BY_ORG_ID,
    SET_MANAGER_ID,
    SET_MANAGER_Name,
    SET_EXECUTIVE_DETAILS,
    VIEW_LEAD_BY_EXECUTIVE,
    VIEW_ALL_MANAGER_BY_ADMIN,
    ADMIN_USER,
    SEARCH_EXECUTIVE

} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import 'firebase/storage';
import { set_snack_bar } from "../../common/snackbar/action";


// ---------------------------------------------EXECUTIVE------------------------------------------------- //
export function addEmployee(org_id, executive_name, email_id, contact_number, emp_shortId, manager_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.SSO_URL + "v1/add_users", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },
            body: JSON.stringify({
                org_id: org_id,
                name: executive_name,
                email_id: email_id,
                mobile: contact_number,
                access: "E",
                emp_id: emp_shortId,
                product: 'crm'
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllAllotment());
                    // dispatch(categoryAcces(responseJson.result.insertedId, role, org_id))
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(viewProductUser(org_id, 'crm'))
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(viewProductUser(org_id, 'crm'))
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(viewProductUser(org_id, 'crm'))
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateEmployee(org_id, executive_name, email_id, contact_number, emp_shortId, manager_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_executive", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },
            body: JSON.stringify({

                executive_name: executive_name,
                email_id: email_id,
                contact_number: contact_number,
                emp_shortId: emp_shortId,
                manager_id: manager_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllAllotment());
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllEmployee(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_executive",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id

                },

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_EMPLOYEE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_EMPLOYEE, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewAllEmployeeById(executive_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_executive_by_id",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    executive_id: executive_id,
                })

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_EMPLOYEE_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_EMPLOYEE_BY_ID, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// -----------------------delete employee---------------------
export function deleteEmployee(org_id, _id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.SSO_URL + "/v1/remove_access", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: _id, 
                org_id: org_id, 
                product: 'crm',
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewProductUser(org_id, 'crm'));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}





// ---------------------------------------------MANAGER------------------------------------------------- //
export function addManager(admin_id, org_id, fullname, email, mobile, employeeID, role, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.SSO_URL + "v1/add_users", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                org_id: org_id,
                name: fullname,
                email_id: email,
                mobile: mobile,
                access: "M",
                emp_id: employeeID,
                product: 'crm'
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(categoryAcces(responseJson.result.insertedId, role, org_id))
                    // dispatch(viewAllManager());
                    // dispatch(viewAllManagerByAdmin(admin_id, org_id, page_number, rowper_page))
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function categoryAcces(user_id, category, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_cataccess", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                user_id: user_id,
                org_id: org_id,
                category: category
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewProductUser(org_id, 'crm'))
                    // dispatch(viewAllManager());
                    // dispatch(viewAllManagerByAdmin(admin_id, org_id, page_number, rowper_page))
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// delete manager
export function deleteManager(manager_id, admin_id, org_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_manager", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                manager_id: manager_id

            })
        })

            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllManager());
                    dispatch(viewAllManagerByAdmin(admin_id, org_id, page_number, rowper_page))
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

//----------------------view manager----------------------
export function viewAllManager() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_manager",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_MANAGER, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_MANAGER, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// --------edit managerrr---------------
export function updateManager(manager_id, fullname, email, mobile, employeeID, role, admin_id, org_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_manager", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                manager_id: manager_id,
                fullname: fullname,
                email: email,
                mobile: mobile,
                employeeID: employeeID,
                role: role
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllManager());
                    dispatch(viewAllManagerByAdmin(admin_id, org_id, page_number, rowper_page))
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// search manager
export function searchMANAGER(all_manager, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_MANAGER, payload: all_manager });
        }
        else {
            const newArray = all_manager.filter((el) => {
                return (el.name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_MANAGER, payload: newArray })
        }
    };
}

// search EXECUTIVE
export function searchEXECUTIVE(all_executive, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_EXECUTIVE, payload: all_executive });
        }
        else {
            const newArray = all_executive.filter((el) => {
                return (el.name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_EXECUTIVE, payload: newArray })
        }
    };
}

export function SearchManager(admin_id, org_id, page_number, rowper_page, name) {
    return (dispatch) => {

        dispatch(unsetLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_manager_by_admin", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "Application/json",

            },
            body: JSON.stringify({
                admin_id: admin_id,
                org_id: org_id,
                name: name,
                page_number: page_number,
                rowper_page: rowper_page
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_MANAGER_BY_ADMIN, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_MANAGER_BY_ADMIN, payload: responseJson.result });
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllManagerByAdmin(admin_id, org_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_manager_by_admin",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    admin_id: admin_id,
                    org_id: org_id,
                    page_number: page_number,
                    rowper_page: rowper_page
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_MANAGER_BY_ADMIN, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_MANAGER_BY_ADMIN, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// all manager by org_id
export function viewAllManagerByorg_id(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_manager_by_orgid",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },
                body: JSON.stringify({

                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_MANAGER_BY_ORG_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_MANAGER_BY_ORG_ID, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function setManagerId(payload) {
    return {
        type: SET_MANAGER_ID,
        payload: payload,
    };
}

export function setManagerName(payload) {
    return {
        type: SET_MANAGER_Name,
        payload: payload,
    };
}

export function setExecutiveDetails(payload) {
    return {
        type: SET_EXECUTIVE_DETAILS,
        payload: payload,
    };
}

// transfer lead (manager)
export function transferManagerLead(manager_id, _id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "transfer_manager_leads", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                manager_id: manager_id,
                _id: _id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllManager());
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// -------------view all category--------------------
export function viewAllCategory(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_category",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_CATEGORY, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_CATEGORY, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// ---------------add executive------------------
export function addExecutiveByAdmin(org_id, admin_id, fullname, email, mobile, employeeID, role, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_executive_by_admin", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                admin_id: admin_id,
                fullname: fullname,
                email: email,
                mobile: mobile,
                employeeID: employeeID,
                role: role
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllExecutiveByAdmin(admin_id, org_id, page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// -----view executive-----------
export function viewAllExecutive() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_executive",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_EXECUTIVE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_EXECUTIVE, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// -------------------update executive-------------------
export function updateExecutive(_id, fullname, email, mobile, employeeID, role, admin_id, org_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_executive", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
                fullname: fullname,
                email: email,
                mobile: mobile,
                employeeID: employeeID,
                role: role
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllExecutiveByAdmin(admin_id, org_id, page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// view executive by admin
export function viewAllExecutiveByAdmin(admin_id, org_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_executive_by_admin",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    admin_id: admin_id,
                    org_id: org_id,
                    page_number: page_number,
                    rowper_page: rowper_page
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_EXECUTIVE_BY_ADMIN, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_EXECUTIVE_BY_ADMIN, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewAllExecutiveByAdminNoPage(admin_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_executive_by_admin_without_pagination",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    admin_id: admin_id,
                    org_id: org_id,
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_EXECUTIVE_BY_ADMIN, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_EXECUTIVE_BY_ADMIN, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// view executive by manager
export function viewExecutiveByManager(manager_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_executive_by_manager",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    manager_id: manager_id,
                    org_id: org_id,
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_EXECUTIVE_BY_MANAGER, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_EXECUTIVE_BY_MANAGER, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// search executive
// export function searchExecutiveByAdmin(all_executive_by_admin, e) {
//     return (dispatch) => {
//         if (e === "") {
//             dispatch({ type: SEARCH_EXECUTIVE_BY_ADMIN, payload: all_executive_by_admin });
//         }
//         else {
//             const newArray = all_executive_by_admin.filter((el) => {
//                 return (el.fullname.toLowerCase().includes(e.toLowerCase()));
//             })
//             dispatch({ type: SEARCH_EXECUTIVE_BY_ADMIN, payload: newArray })
//         }
//     };
// }


export function searchExecutiveByAdmin(admin_id, org_id, page_number, rowper_page, name) {
    return (dispatch) => {
        dispatch(unsetLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_executive_by_admin", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "Application/json",

            },
            body: JSON.stringify({
                admin_id: admin_id,
                org_id: org_id,
                name: name,
                page_number: page_number,
                rowper_page: rowper_page
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_EXECUTIVE_BY_ADMIN, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_EXECUTIVE_BY_ADMIN, payload: responseJson.result });
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// delete executive
export function deleteExecutive(executive_id, admin_id, org_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_executive", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                executive_id: executive_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllExecutiveByAdmin(admin_id, org_id, page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// transfer lead (executive)
export function transferExecutiveLead(executive_id, _id, admin_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "transfer_executive_leads", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                executive_id: executive_id,
                _id: _id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllExecutiveByAdmin(admin_id, org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


// add executive by manager
export function addExecutiveByManager(org_id, manager_id, fullname, email, mobile, employeeID, role, page_number, rowper_page) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.SSO_URL + "v1/add_users", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                org_id: org_id,
                name: fullname,
                email_id: email,
                mobile: mobile,
                access: "E",
                emp_id: employeeID,
                product: 'crm'
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(categoryAcces(responseJson.result.insertedId, role, org_id))
                    // dispatch(view_executive_by_manager(manager_id, org_id, page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// view executive by manager
export function view_executive_by_manager(manager_id, org_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_executives_by_managers",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    manager_id: manager_id,
                    org_id: org_id,
                    page_number: page_number,
                    rowper_page: rowper_page
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_EXECUTIVE_BY_ADMIN, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_EXECUTIVE_BY_ADMIN, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function search_executive_by_manager(manager_id, org_id, page_number, rowper_page, name) {
    return (dispatch) => {

        dispatch(unsetLoader());
        return fetch(UNIVERSAL.BASEURL + "view_executives_by_managers", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "Application/json",

            },
            body: JSON.stringify({
                manager_id: manager_id,
                org_id: org_id,
                name: name,
                page_number: page_number,
                rowper_page: rowper_page
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_EXECUTIVE_BY_ADMIN, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_EXECUTIVE_BY_ADMIN, payload: responseJson.result });
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// view leads by executive

// export function viewLeadByExecutive(executive_id) {
//     return (dispatch) => {
//         
//         dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "view_lead_deal_by_executive",
//             {
//                 method: "GET",
//                 headers: {
//                     Accept: "application/json",
//                     "Content-Type": "application/json",
//                     executive_id:executive_id
//                 },
//                 // body: JSON.stringify({
//                 // })
//             })
//             .then((response) => response.json())
//             .then((responseJson) => {
//                 if (responseJson.status) {
//                     dispatch({ type: VIEW_LEAD_BY_EXECUTIVE, payload: responseJson.result });
//                     dispatch(set_snack_bar(responseJson.status, "Some message"));
//                     dispatch(unsetLoader());
//                 } else {
//                     dispatch({ type: VIEW_LEAD_BY_EXECUTIVE, payload: [] })
//                 }
//                 dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }


export function viewLeadByExecutive(executive_id) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_lead_deal_by_executive",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    executive_id: executive_id
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_LEAD_BY_EXECUTIVE, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_LEAD_BY_EXECUTIVE, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function assignExecutiveToManager(executive_id, manager_id, org_id) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "assign_executive_to_manager", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                executive_id: executive_id,
                manager_id: manager_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewExecutiveByManager(manager_id, org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewProductUser(org_id, product) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.SSO_URL + "v1/view_access_org_product", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                org_id: org_id,
                product: product
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                
                if (responseJson.status) {
                    dispatch({ type: ADMIN_USER, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: ADMIN_USER, payload: [] })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                dispatch({ type: ADMIN_USER, payload: [] })
                console.error(error);
            });
    };
}

export function updatePersonalDetails(org_id, user_id, name, email, mobile) {
    return (dispatch) => {
        
        dispatch(setLoader());
        return fetch(UNIVERSAL.SSO_URL + 'v1/update_user', {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: user_id,
                name: name,
                email_id: email,
                mobile: mobile,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewProductUser(org_id, 'crm'));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(viewProductUser(org_id, 'crm'));
                }
                dispatch(unsetLoader());
                dispatch(viewProductUser(org_id, 'crm'));
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}














