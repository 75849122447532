import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNewLead from "../component/AdminNewLead";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    viewAllLeadWithPgn,
    viewAllOldLead,
    viewAllFollowUpLead,
    viewAllSource,
    addNewLead,
    viewAllNewLead,
    viewAllNewLeadManager,
    viewAllFollowUpLeadManager,
    viewAllOldLeadManager,
    searchNewLead,
    searchFollowUpsLead,
    searchOldLead,
    setLeadDetails,
    import_lead,
    view_lead_details,
    viewAllFollowUpLeadExecutive,
    viewAllOldLeadExecutive,
    viewAllNewLeadExecutive

} from "../action";
import { viewAllProduct } from "../../product/Action";
import { viewAllCategory } from "../../employee/actions"
export class Controller extends Component {
    render() {
        return (
            <AdminNewLead {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        product: store.product,
        login: store.login,
        employee: store.employee,
        leads: store.leads,
        loader: store.loader,
        // snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        viewAllLeadWithPgn: (org_id, page_number, rowper_page) => {
            dispatch(viewAllLeadWithPgn(org_id, page_number, rowper_page));
        },
        viewAllOldLead: (org_id, rowper_page, page_number, name) => {
            dispatch(viewAllOldLead(org_id, rowper_page, page_number, name));
        },
        viewAllFollowUpLead: (org_id, rowper_page, page_number, name) => {
            dispatch(viewAllFollowUpLead(org_id, rowper_page, page_number, name));
        },
        view_lead_details: (lead_id) => {
            dispatch(view_lead_details(lead_id));
        },
        viewAllSource: () => {
            dispatch(viewAllSource());
        },
        addNewLead: (org_id, phone, add_by, follow_up_date, email, source, product_id, executive_id, name, description, reminder, call_purpose, category, product_name, product_price, countryid, stateid, cityid, address, pincode, deal_status) => {
            dispatch(addNewLead(org_id, phone, add_by, follow_up_date, email, source, product_id, executive_id, name, description, reminder, call_purpose, category, product_name, product_price, countryid, stateid, cityid, address, pincode, deal_status));
        },
        import_lead: (leadData, product_name, product_price, org_id) => {
            dispatch(import_lead(leadData, product_name, product_price, org_id));
        },

        viewAllProduct: (org_id) => {
            dispatch(viewAllProduct(org_id));
        },

        viewAllNewLead: (org_id, rowper_page, page_number, name) => {
            dispatch(viewAllNewLead(org_id, rowper_page, page_number, name));
        },
        viewAllNewLeadManager: (org_id, user_id, rowper_page, page_number, name) => {
            dispatch(viewAllNewLeadManager(org_id, user_id, rowper_page, page_number, name));
        },
        viewAllNewLeadExecutive: (org_id, user_id, rowper_page, page_number, name) => {
            dispatch(viewAllNewLeadExecutive(org_id, user_id, rowper_page, page_number, name));
        },
        viewAllFollowUpLeadManager: (org_id, user_id, rowper_page, page_number) => {
            dispatch(viewAllFollowUpLeadManager(org_id, user_id, rowper_page, page_number));
        },
        viewAllFollowUpLeadExecutive: (org_id, user_id, rowper_page, page_number) => {
            dispatch(viewAllFollowUpLeadExecutive(org_id, user_id, rowper_page, page_number));
        },
        viewAllOldLeadManager: (org_id, user_id, rowper_page, page_number) => {
            dispatch(viewAllOldLeadManager(org_id, user_id, rowper_page, page_number));
        },
        viewAllOldLeadExecutive: (org_id, user_id, rowper_page, page_number) => {
            dispatch(viewAllOldLeadExecutive(org_id, user_id, rowper_page, page_number));
        },
        searchNewLead: (all_new_lead, e) => {
            dispatch(searchNewLead(all_new_lead, e));
        },

        viewAllCategory: (org_id) => {
            dispatch(viewAllCategory(org_id));
        },
        searchFollowUpsLead: (all_followups_lead, e) => {
            dispatch(searchFollowUpsLead(all_followups_lead, e));
        },

        searchOldLead: (all_old_lead, e) => {
            dispatch(searchOldLead(all_old_lead, e));
        },

        setLeadDetails: (payload) => {
            dispatch(setLeadDetails(payload));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);