import React, { Component } from "react";
import {
    Grid,
    Box,
    IconButton,
    Button,
    Typography, TableContainer,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    MenuItem
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import InputBase from "@mui/material/InputBase";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import TextField from "@mui/material/TextField";
import Divider from '@mui/material/Divider';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { Edit2, Trash, Add, CloseCircle, SearchNormal } from 'iconsax-react';
import Crmlogo from "../../../pages/image/crmlogo.png";
import { Avatar } from '@mui/material';
class product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            open: false,

            viewData: false,
            addData: false,
            editData: false,
            productdelete: false,
            productwholedelete: false,

            org_id: "",
            product_id: "",
            product_name: "",
            old_product_img: "",
            product_img: "",
            product_shortId: "",
            product_price: "",
            product_stock: "",
            product_desc: "",
            product_type: "",

            page: 0,
            setPage: 0,
            rowsPerPage: 10,
        };
    }

    componentDidMount() {
        this.props.viewAllProductWithPgn(
            this.props.login.org_id,
            this.state.page,
            this.state.rowsPerPage
        );

    }

    CheckedService(p, index, service) {
        // 
        if (p.target.checked) {
            this.props.CheckedService(index, true, 1, service)
        }
        else {
            this.props.CheckedService(index, false, 0, service)
        }
    }

    AllCheckedService(p, service) {
        // 
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheck(true, 1, service)
        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheck(false, 0, service)
        }

    }

    render() {
        const handleChangePage = (event, newPage) => {
            this.props.viewAllProductWithPgn(
                this.props.login.org_id,
                newPage - 1,
                this.state.rowsPerPage
            );
            this.setState({
                page: newPage,
            });
        };

        return (

            <div>
                <Grid>
                    <Grid
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "2%",
                        }}
                    >
                        <Typography style={{ fontSize: "25px", fontWeight: "500" }}>
                            Products
                        </Typography>

                        {this.props.login.type === "Owner" && <Grid
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "20px",
                            }}
                        >
                            <Paper
                                component="form"
                                style={{
                                    width: '21.375rem',
                                    height: '2.5rem',
                                    gap: '0.625rem',
                                    borderRadius: '1.25rem',
                                    border: '1px solid var(--PrimaryColour-G7, #B8B8B8)'
                                }}
                            >
                                <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                                    <SearchNormal
                                        size="24"
                                        color="var(--PrimaryColour-G7, #B8B8B8)" />
                                </IconButton>
                                <InputBase
                                    className='search-text'
                                    placeholder="Search "
                                    inputProps={{ "aria-label": "Search " }}
                                    onChange={(e) => {
                                        // this.props.searchProductByAdmin(this.props.product.view_all_product_with_pagination, e.target.value);
                                        this.props.SearchProduct(this.props.login.org_id,
                                            this.state.page,
                                            this.state.rowsPerPage, e.target.value);
                                    }}
                                />
                            </Paper>
                            <Box style={{ borderRadius: "6px", cursor: "pointer" }}>
                                <Add
                                    size="24px"
                                    color="#525050"
                                    variant="Bold"
                                    cursor={'pointer'}
                                    onClick={() => {
                                        this.setState({
                                            addData: true,
                                            product_img: "",
                                            product_shortId: "",
                                            product_name: "",
                                            product_price: "",
                                            product_stock: "",
                                            product_desc: "",
                                            product_type: "",
                                        });
                                    }}
                                />
                            </Box>

                            {/* <Box style={{ cursor: "pointer" }}>
                                <img alt=""
                                    src={Deleteico}
                                    style={{
                                        background: "#AA3F1D",
                                        padding: "10px",
                                        borderRadius: "4px",
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            productwholedelete: true,
                                         



                                        });
                                    }}
                                />
                            </Box> */}
                        </Grid>}
                    </Grid>
                    <Divider style={{ border: "1px solid var(--primary-colour-g-6, #A3A3A3)" }} />

                    <Grid style={{ marginTop: "2%" }}>
                        <TableContainer component={Paper} style={{ borderRadius: '0px' }}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead style={{ background: "lightgray" }}>
                                    <TableRow>
                                        <TableCell style={{ color: "#3D3B3B", textAlign: "left" }}>SL No.</TableCell>
                                        <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Product Image</TableCell>
                                        <TableCell style={{ color: "#3D3B3B", textAlign: "left" }}>Product Name</TableCell>
                                        <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Product ID</TableCell>
                                        <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Product Price</TableCell>
                                        <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Product Stock</TableCell>
                                        {this.props.login.type === "Owner" && <TableCell>Actions</TableCell>}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {Array.isArray(
                                        this.props.product.view_all_product_with_pagination
                                    ) &&
                                        this.props.product.view_all_product_with_pagination.sort((a, b) => (a._id > b._id ? -1 : 1)).map(
                                            (item, index) => {
                                                return (
                                                    <TableRow hover={true} style={{ cursor: "pointer" }}>
                                                        <TableCell style={{ textAlign: "left" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    viewData: true,
                                                                    product_id: item._id,
                                                                    old_product_img: item.product_img,
                                                                    product_shortId: item.product_shortId,
                                                                    product_name: item.product_name,
                                                                    product_price: item.product_price,
                                                                    product_stock: item.product_stock,
                                                                    product_desc: item.product_desc,
                                                                    product_type: item.product_type,

                                                                });
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: "center" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    viewData: true,
                                                                    product_id: item._id,
                                                                    old_product_img: item.product_img,
                                                                    product_shortId: item.product_shortId,
                                                                    product_name: item.product_name,
                                                                    product_price: item.product_price,
                                                                    product_stock: item.product_stock,
                                                                    product_desc: item.product_desc,
                                                                    product_type: item.product_type,
                                                                });
                                                            }}
                                                        >
                                                            {item.product_img === '' ?
                                                                <PhotoSizeSelectActualIcon style={{ height: "40px", width: "80px" }} />
                                                                :
                                                                <img alt=""
                                                                    src={item.product_img}
                                                                    style={{ height: "40px", width: "80px", alignItems: "center", borderRadius: "5px" }}
                                                                />
                                                            }
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: "left" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    viewData: true,
                                                                    product_id: item._id,
                                                                    old_product_img: item.product_img,
                                                                    product_shortId: item.product_shortId,
                                                                    product_name: item.product_name,
                                                                    product_price: item.product_price,
                                                                    product_stock: item.product_stock,
                                                                    product_desc: item.product_desc,
                                                                    product_type: item.product_type,
                                                                });
                                                            }}
                                                        >
                                                            {item.product_name}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: "center" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    viewData: true,
                                                                    product_id: item._id,
                                                                    old_product_img: item.product_img,
                                                                    product_shortId: item.product_shortId,
                                                                    product_name: item.product_name,
                                                                    product_price: item.product_price,
                                                                    product_stock: item.product_stock,
                                                                    product_desc: item.product_desc,
                                                                    product_type: item.product_type,
                                                                });
                                                            }}
                                                        >
                                                            {item.product_shortId}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: "center" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    viewData: true,
                                                                    product_id: item._id,
                                                                    old_product_img: item.product_img,
                                                                    product_shortId: item.product_shortId,
                                                                    product_name: item.product_name,
                                                                    product_price: item.product_price,
                                                                    product_stock: item.product_stock,
                                                                    product_desc: item.product_desc,
                                                                    product_type: item.product_type,
                                                                });
                                                            }}
                                                        >
                                                            {" "}
                                                            {item.product_price}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: "center" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    viewData: true,
                                                                    product_id: item._id,
                                                                    old_product_img: item.product_img,
                                                                    product_shortId: item.product_shortId,
                                                                    product_name: item.product_name,
                                                                    product_price: item.product_price,
                                                                    product_stock: item.product_stock,
                                                                    product_desc: item.product_desc,
                                                                    product_type: item.product_type,
                                                                });
                                                            }}
                                                        >
                                                            {" "}
                                                            {item.product_stock === '' ? '---' : item.product_stock}
                                                        </TableCell>
                                                        {this.props.login.type === "Owner" &&
                                                            <TableCell style={{ justifyContent: "center" }} >
                                                                <Grid style={{ display: "flex", justifyContent: 'space-evenly' }}>
                                                                    <Trash
                                                                        size="24px"
                                                                        color="#C43232"
                                                                        variant="Outline"
                                                                        cursor={'pointer'}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                productdelete: true,
                                                                                product_id: item._id,
                                                                            });
                                                                        }}
                                                                    />
                                                                    <Edit2
                                                                        size="24px"
                                                                        color="green"
                                                                        variant="Outline"
                                                                        cursor={'pointer'}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                editData: true,
                                                                                product_id: item._id,
                                                                                old_product_img: item.product_img,
                                                                                product_shortId: item.product_shortId,
                                                                                product_name: item.product_name,
                                                                                product_price: item.product_price,
                                                                                product_stock: item.product_stock,
                                                                                product_desc: item.product_desc,
                                                                                product_type: item.product_type,

                                                                            });

                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </TableCell>}
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                </TableBody>
                            </Table>
                            <Pagination style={{ display: "flex", justifyContent: "flex-end", padding: '1%' }}
                                count={parseInt(this.props.product.view_all_product_with_pagination.length / 10) + 1}
                                size="small"
                                className='pagination'
                                onChange={handleChangePage}
                            />
                        </TableContainer>
                    </Grid>

                    {/* add dialog */}
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={this.state.addData}>
                        <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'#D9D9D9'} marginBottom={'10px'}>
                            <Typography variant='h7'>Add Product</Typography>
                            <CloseCircle
                                size="24px"
                                variant="Outline"
                                cursor={'pointer'}
                                onClick={() => {
                                    this.setState({ addData: false });
                                }}
                                className="eventcross-icon"
                            />
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                fullWidth
                                style={{
                                    height: "100px",
                                    borderRadius: '0.9375rem',
                                    border: "3px solid var(--PrimaryColour-G4, #7A7A7A)",
                                    backgroundColor: "#FCFCFC",
                                    position: 'relative', // Add position relative to contain the absolute positioned img
                                }}
                            >
                                {this.state.product_img !== "" ?
                                    <label
                                        htmlFor="file"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    >
                                        {/* Add a container div to handle absolute positioning */}
                                        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                                            <img
                                                alt=""
                                                src={this.state.product_img ? URL.createObjectURL(this.state.product_img) : ""}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover', // or 'contain' depending on your requirement
                                                    borderRadius: '0.92rem',
                                                }}
                                            />
                                        </div>
                                    </label>
                                    :
                                    <label
                                        htmlFor="file"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" cursor="pointer" viewBox="0 0 27 27" fill="none">
                                            <g clip-path="url(#clip0_271_8272)">
                                                <path d="M22.5 6.75H13.5L11.25 4.5H4.5C3.2625 4.5 2.26125 5.5125 2.26125 6.75L2.25 20.25C2.25 21.4875 3.2625 22.5 4.5 22.5H22.5C23.7375 22.5 24.75 21.4875 24.75 20.25V9C24.75 7.7625 23.7375 6.75 22.5 6.75ZM22.5 20.25H4.5V9H22.5V20.25ZM9 14.6363L10.5863 16.2225L12.375 14.445V19.125H14.625V14.445L16.4137 16.2338L18 14.6363L13.5113 10.125L9 14.6363Z" fill="#8A8A8A" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_271_8272">
                                                    <rect width="27" height="27" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Product Image Add
                                    </label>
                                }
                                <input
                                    style={{ display: "none", cursor: "pointer" }}
                                    type="file"
                                    id="file"
                                    onChange={(e) => {
                                        // Ensure that e.target.files is not empty and contains a File object
                                        if (e.target.files && e.target.files.length > 0) {
                                            this.setState({ product_img: e.target.files[0] });
                                        }
                                    }}
                                />
                            </Box>
                            <Grid container spacing={2} marginTop={'0.2%'}>
                                <Grid item xs={4}>
                                    <Typography>Product Name <span style={{ color: 'red' }}>*</span></Typography>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        id="standard-basic"
                                        variant="standard"
                                        onChange={(e) => {
                                            this.setState({ product_name: e.target.value });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Product ID</Typography>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        id="standard-basic"
                                        variant="standard"
                                        onChange={(e) => {
                                            this.setState({ product_shortId: e.target.value });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography >Product Price <span style={{ color: 'red' }}>*</span></Typography>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        id="standard-basic"
                                        variant="standard"
                                        onChange={(e) => {
                                            this.setState({ product_price: e.target.value });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography >Product Type</Typography>
                                    <TextField
                                        select
                                        type="text"
                                        fullWidth
                                        id="standard-basic"
                                        variant="standard"
                                        onChange={(e) => {
                                            this.setState({ product_type: e.target.value });
                                        }}
                                    >
                                        <MenuItem value="Service">Service</MenuItem>
                                        <MenuItem value="Product">Product</MenuItem>
                                    </TextField>
                                </Grid>
                                {this.state.product_type === 'Product' &&
                                    <Grid item xs={6}>
                                        <Typography >Product Stock</Typography>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            id="standard-basic"
                                            variant="standard"
                                            onChange={(e) => {
                                                this.setState({ product_stock: e.target.value });
                                            }}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Typography >Description</Typography>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        multiline
                                        onChange={(e) => {
                                            this.setState({ product_desc: e.target.value });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <Button
                                variant='contained'
                                disabled={this.state.product_name === "" || this.state.product_price === ""}
                                style={{
                                    borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white'
                                }}
                                onClick={() => {
                                    this.setState({
                                        addData: false,
                                        org_id: "",
                                        product_img: "",
                                        product_shortId: "",
                                        product_name: "",
                                        product_price: "",
                                        product_stock: "",
                                        product_desc: "",
                                        product_type: "",
                                    });
                                    this.props.uploadImageProduct(
                                        this.props.login.org_id,
                                        this.state.product_img,
                                        this.state.product_shortId,
                                        this.state.product_name,
                                        this.state.product_price,
                                        this.state.product_stock,
                                        this.state.product_desc,
                                        this.state.product_type
                                    );
                                }}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* view dialog*/}
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={this.state.viewData}>
                        <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'#D9D9D9'} marginBottom={'10px'}>
                            <Typography variant='h7'>View Product</Typography>
                            <CloseCircle
                                size="24px"
                                variant="Outline"
                                cursor={'pointer'}
                                onClick={() => {
                                    this.setState({ viewData: false });
                                }}
                                className="eventcross-icon"
                            />
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                fullWidth
                                style={{
                                    height: "100px",
                                    borderRadius: '0.9375rem',
                                    border: "3px solid var(--PrimaryColour-G4, #7A7A7A)",
                                    backgroundColor: "#FCFCFC",
                                    position: 'relative', // Add position relative to contain the absolute positioned img
                                }}
                            >
                                <label
                                    htmlFor="file"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        width: '100%',
                                    }}
                                >
                                    <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                                        {this.state.old_product_img === '' ?
                                            <PhotoSizeSelectActualIcon style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover', // or 'contain' depending on your requirement
                                                borderRadius: '0.92rem',
                                            }} />
                                            :
                                            <img
                                                alt=""
                                                src={this.state.product_img ? URL.createObjectURL(this.state.product_img) : this.state.old_product_img}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover', // or 'contain' depending on your requirement
                                                    borderRadius: '0.92rem',
                                                }}
                                            />
                                        }
                                    </div>
                                </label>
                            </Box>
                            <Grid container spacing={2} marginTop={'0.2%'}>
                                <Grid item xs={4}>
                                    <Typography>Product Name</Typography>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        id="standard-basic"
                                        variant="standard"
                                        value={this.state.product_name}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Product ID</Typography>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        id="standard-basic"
                                        variant="standard"
                                        value={this.state.product_shortId}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Product Price</Typography>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        id="standard-basic"
                                        variant="standard"
                                        value={this.state.product_price}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Product Type</Typography>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        id="standard-basic"
                                        variant="standard"
                                        value={this.state.product_type}
                                        disabled
                                    />
                                </Grid>
                                {this.state.product_type === 'Product' &&
                                    <Grid item xs={6}>
                                        <Typography>Product Stock</Typography>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            id="standard-basic"
                                            variant="standard"
                                            value={this.state.product_stock}
                                            disabled
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Typography>Description</Typography>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        multiline
                                        value={this.state.product_desc}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <Button variant='contained'
                                style={{
                                    borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white'
                                }}
                                onClick={() => {
                                    this.setState({
                                        viewData: false,
                                    });
                                }}
                            >
                                Okay
                            </Button>
                        </DialogActions>
                    </Dialog>



                    {/* edit dialog*/}

                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={this.state.editData}>
                        <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'#D9D9D9'} marginBottom={'10px'}>
                            <Typography variant="h6">Edit Product</Typography>
                            <CloseCircle
                                size="24px"
                                variant="Outline"
                                cursor={'pointer'}
                                onClick={() => {
                                    this.setState({ editData: false });
                                }}
                                className="eventcross-icon"
                            />
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                fullWidth
                                style={{
                                    height: "100px",
                                    borderRadius: '0.9375rem',
                                    border: "3px solid var(--PrimaryColour-G4, #7A7A7A)",
                                    backgroundColor: "#FCFCFC",
                                    position: 'relative', // Add position relative to contain the absolute positioned img
                                }}
                            >
                                {this.state.old_product_img !== "" ?
                                    <label
                                        htmlFor="file"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    >
                                        {/* Add a container div to handle absolute positioning */}
                                        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                                            <img
                                                alt=""
                                                src={this.state.product_img ? URL.createObjectURL(this.state.product_img) : this.state.old_product_img}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover', // or 'contain' depending on your requirement
                                                    borderRadius: '0.92rem',
                                                }}
                                            />
                                        </div>
                                    </label>
                                    :
                                    <label
                                        htmlFor="file"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" cursor="pointer" viewBox="0 0 27 27" fill="none">
                                            <g clip-path="url(#clip0_271_8272)">
                                                <path d="M22.5 6.75H13.5L11.25 4.5H4.5C3.2625 4.5 2.26125 5.5125 2.26125 6.75L2.25 20.25C2.25 21.4875 3.2625 22.5 4.5 22.5H22.5C23.7375 22.5 24.75 21.4875 24.75 20.25V9C24.75 7.7625 23.7375 6.75 22.5 6.75ZM22.5 20.25H4.5V9H22.5V20.25ZM9 14.6363L10.5863 16.2225L12.375 14.445V19.125H14.625V14.445L16.4137 16.2338L18 14.6363L13.5113 10.125L9 14.6363Z" fill="#8A8A8A" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_271_8272">
                                                    <rect width="27" height="27" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Product Image Add
                                    </label>
                                }
                                <input
                                    style={{ display: "none", cursor: "pointer" }}
                                    type="file"
                                    id="file"
                                    onChange={(e) => {
                                        // Ensure that e.target.files is not empty and contains a File object
                                        if (e.target.files && e.target.files.length > 0) {
                                            this.setState({ product_img: e.target.files[0] });
                                        }
                                    }}
                                />
                            </Box>
                            <Grid container spacing={2} marginTop={'0.2%'}>
                                <Grid item xs={4}>
                                    <Typography>Product Name <span style={{ color: 'red' }}>*</span></Typography>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        id="standard-basic"
                                        variant="standard"
                                        onChange={(e) => {
                                            this.setState({ product_name: e.target.value });
                                        }}
                                        value={this.state.product_name}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Product ID</Typography>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        id="standard-basic"
                                        variant="standard"
                                        onChange={(e) => {
                                            this.setState({ product_shortId: e.target.value });
                                        }}
                                        value={this.state.product_shortId}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography >Product Price <span style={{ color: 'red' }}>*</span></Typography>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        id="standard-basic"
                                        variant="standard"
                                        onChange={(e) => {
                                            this.setState({ product_price: e.target.value });
                                        }}
                                        value={this.state.product_price}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Product Type</Typography>
                                    <TextField
                                        type="text"
                                        select
                                        fullWidth
                                        id="standard-basic"
                                        variant="standard"
                                        onChange={(e) => {
                                            this.setState({ product_type: e.target.value });
                                        }}
                                        value={this.state.product_type}
                                    >
                                        <MenuItem value="Service">Service</MenuItem>
                                        <MenuItem value="Product">Product</MenuItem>
                                    </TextField>
                                </Grid>
                                {this.state.product_type === 'Product' &&
                                    <Grid item xs={6}>
                                        <Typography>Product Stock</Typography>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            id="standard-basic"
                                            variant="standard"
                                            onChange={(e) => {
                                                this.setState({
                                                    product_stock: e.target.value
                                                });
                                            }}
                                            value={this.state.product_stock}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Typography>Description</Typography>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        multiline
                                        onChange={(e) => {
                                            this.setState({ product_desc: e.target.value });
                                        }}
                                        value={this.state.product_desc}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <Button variant='contained'
                                disabled={this.state.product_name === "" || this.state.product_price === ""}
                                style={{
                                    borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white'
                                }}
                                onClick={() => {
                                    this.setState({
                                        editData: false,
                                        org_id: "",
                                        product_id: "",
                                        product_img: "",
                                        product_shortId: "",
                                        product_name: "",
                                        product_price: "",
                                        product_stock: "",
                                        product_desc: "",
                                        product_type: "",
                                    });
                                    (this.state.product_type === 'Product' ?
                                        this.props.updateimageproduct(
                                            this.props.login.org_id,
                                            this.state.product_id,
                                            this.state.old_product_img,
                                            this.state.product_img,
                                            this.state.product_shortId,
                                            this.state.product_name,
                                            this.state.product_price,
                                            this.state.product_stock,
                                            this.state.product_desc,
                                            this.state.product_type
                                        )
                                        :
                                        this.props.updateimageproduct(
                                            this.props.login.org_id,
                                            this.state.product_id,
                                            this.state.old_product_img,
                                            this.state.product_img,
                                            this.state.product_shortId,
                                            this.state.product_name,
                                            this.state.product_price,
                                            '',
                                            this.state.product_desc,
                                            this.state.product_type
                                        )
                                    )
                                }}
                            >
                                Update
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* delete dialog*/}

                    {/* <Dialog open={this.state.productwholedelete}>
                        <Grid style={{ height: "210px", width: "300px" }}>
                            <Grid
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    padding: 10,
                                }}
                            >
                               
                                <CloseOutlinedIcon
                                    onClick={() => {
                                        this.setState({ productwholedelete: false });
                                    }}
                                    style={{
                                        fontSize: 20,
                                        color: "#000",
                                        cursor: "pointer",
                                        marginLeft: "250px",
                                        marginTop: "3%",
                                    }}
                                />
                            </Grid>
                          

                            <Typography
                                style={{
                                    textAlign: "center",
                                    marginTop: "5%",
                                    color: "#000",
                                    fontWeight: 600,
                                }}
                            >
                                Are you sure you want to delete ?
                            </Typography>

                            <Grid
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    marginTop: "10%",
                                    gap: "10px",
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        this.setState({ productwholedelete: false });
                                        this.props.bulkDeleteProduct(
                                            
                                            this.state.product_id,
                                        );
                                    }}
                                        
                                   
                                    variant="outlined"
                                    style={{ borderColor: "gray", color: "gray" }}
                                >
                                    Yes
                                </Button>
                                <Button
                                  
                                    variant="contained"
                                    style={{
                                        backgroundColor: "gray",
                                        color: "#fff",
                                        marginRight: "4%",
                                    }}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    </Dialog> */}

                    {/* delete row data */}
                    <Dialog
                        maxWidth="xs"
                        open={this.state.productdelete}
                    >
                        <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'lightgray'}>
                            <Typography variant='h6'>Delete</Typography>
                            <CloseCircle
                                size="24"
                                color="black"
                                variant="Outline"
                                cursor={'pointer'}
                                onClick={() => {
                                    this.setState({
                                        productdelete: false,
                                    });
                                }} />
                        </DialogTitle>
                        <DialogContent style={{ marginTop: '20px' }}>
                            <DialogContentText >Are you sure, you want to delete ?</DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ marginBottom: '10px', justifyContent: 'center' }}>
                            <Button style={{ backgroundColor: "#3D3B3B", color: "#E5F6FF", borderRadius: 10, }}
                                onClick={() => {
                                    this.setState({ productdelete: false });
                                    this.props.deleteProduct(
                                        this.props.login.org_id,
                                        this.state.product_id,
                                    );
                                }}
                            >
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid >
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
            </div >
        );
    }
}

export default product;
